.pagination {
    display: flex;
    width: 88.5%;
    flex-wrap: wrap;
    justify-content: flex-end;
    background-color: white;
    border-radius: 0px;
    padding: 20px 10px;
    margin-top: 20px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: "IBM Plex Sans", sans-serif;
    position: sticky;
    bottom: 0;
  }
  
  .pagination .number {
    display: flex;
    text-transform: capitalize;
    margin-right: 10px;
    align-items: center;
  }
  
  .pagination .change {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  
  .pagination .line {
    display: flex;
    align-items: center;
  }
  
  .pagination .change button {
    border: none;
    border-radius: 10px;
    align-items: center;
    display: flex;
    padding: 10px;
  }
  
  .pagination .change .current {
    margin: 10px;
  }
  
  .currentpage {
    outline: none;
    width: 15px;
    border: none;
    border-radius: 10px;
    align-items: center;
    display: flex;
    padding: 10px;
    background-color: #f0f0f0;
  }
  
  .pointer {
    cursor: pointer;
  }
  
  
  .back_button {
    border: none;
    padding: 15px 15px;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }
  
.question_bank_table_top {
  padding: 30px 30px;
}

.question_bank {
  width: 100%;
 
  /* padding: 30px 30px; */
}

.pointer {
  cursor: pointer;
}

.question_bank .table_content_report {
  background-color: #ffffff;
  height: 83vh;
  overflow-y: scroll;
  border-radius: 12px;
}



.table_content_report .top_content_table {
  width: 100%;
  height: 77px;
  display: flex;
  align-items: center;
}

.table_content_report .top_content_table .table_title {
  height: 31px;
  gap: 24px;
  display: flex;
  width: 30%;
  margin-left: 20px;
}

.table_content_report .top_content_table .table_title span {
  font-size: 16px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 20px;
}


.table_content_report .top_content_table .user_management_search_listing {
  margin: 10px 30px;
 
}

.table_content_report .top_content_table .search_listing {
  width: 20%;
  height: 40px;
  background-color: rgba(255, 255, 255, 1);

  border-radius: 10px;
  box-shadow: 0px 0px 10px #eceff1;
}

.search_listing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search_listing .tag_icon {
  display: flex;
  justify-content: left;
  align-items: start;
  padding-left: 5px;
  max-width: 100px;
  width: 30px;

}



.search_listing .input_search {
  height: 100%;
  display: flex;
  width: 80%;
  position: relative;


}

.search_listing .input_search input,
.search_listing .input_search input:focus-visible {
  border: 10px;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
}




.filter {
  width: 20%;
  height: 24px;
  display: flex;
  justify-content: end;
  margin-right: 20px;
 
}

.filter div {
  display: inline-flex;
  align-items: center;
}

.filter div span {
  font-size: 14px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
}

.table_content_report .top_content_table .add_question {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 40px;
  margin-left: 27em;
  gap: 50px;
  font-size: 14px;
}

.add_new_question {
  background-color: #00abfb;
  max-width: 228px;
  width: 100%;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_new_question span {
  font-size: 16px;
  color: #ffffff;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 20px;
  margin-left: 10px;
}

.sub_head_content_table table tbody tr td {
  border-bottom: 2px solid #f6f7fb !important;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 13px;
  padding-right: 13px;
}

.table_content_report .sub_head_content_table table {
  border-spacing: 0px !important;
  /* max-width: 1158px; */
  width: 100%;
}

.table_content_report .sub_head_content_table table thead {
  background-color: #e9f8ff;
  height: 41px;
  text-align: center;
  font-size: 12px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 14px;
}

.table_content_report .sub_head_content_table table thead .very_first {
  text-align: left !important;
  padding-left: 26px;
  height: 28px;
  /* width: 147px; */
}

.table_content_report .sub_head_content_table table thead .first {
  height: 28px;
  /* width: 110px; */
}

.table_content_report .sub_head_content_table table thead .second {
  height: 28px;
  /* width: 86px; */
}

.table_content_report .sub_head_content_table table thead .third {
  height: 28px;
  /* width: 105px; */
}

.table_content_report .sub_head_content_table table thead .fourth {
  height: 28px;
  /* width: 66px; */
}

.table_content_report .sub_head_content_table table thead .fifth {
  height: 28px;
  /* width: 74px; */
}

.table_content_report .sub_head_content_table table thead th span {
  margin-right: 4px;
}

.user_management_table_body_content {
  font-size: 12px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.activate {
  background-color: #e9fcf1;
  color: #26ab5b;
  width: 62px;
  height: 20px;
  border: 0.3px solid #26ab5b;
  border-radius: 4px;
  font-size: 10px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  padding: 4px 8px 4px 8px;

}

.inactive {
  background-color: #f6f7fb;
  color: #8e9eab;
  width: 62px;
  height: 20px;
  border: 0.3px solid #8e9eab;
  border-radius: 4px;
  font-size: 10px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  padding: 4px 8px 4px 8px;
  float: right;
}

.action {
  /* margin-left: 26px; */
  margin-right: 26px;
  position: relative;
}

.top_section {
  display: block;
}

.top_section .left {
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
}

.top_section .right {
  display: inline-flex;
  float: right;
  align-items: center;
  color: #00abfb;
  font-size: 16px;
  font-weight: 400;
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 20px;
}

.profile_detail_div .status {
  float: right;
  background-color: #e9fcf1;
  border: 1px solid #26ab5b;
  color: #26ab5b;
  font-size: 16px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 20px;
  border-radius: 10px;
  padding: 5px 20px;
}

.profile_detail_div {
  font-size: 18px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 26px;
  padding: 20px 40px 10px 30px;
}

.profile_title_div {
  font-size: 18px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 26px;
  margin-left: 30px;
}

.profile_status {
  padding: 10px 30px;
}

.organisation_table {
  width: 70%;
}

.organisation_detail_head {
  height: auto !important;
}

.oragnisation_status {
  display: flex;
}

.status_Organisation {
  display: inline-block;
  float: right;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
}

.tbody_question_bank {
  display: flex;
  width: 100%;
  font-size: 18px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 20px;
  border-bottom: 1px solid #ecf3f5;
}

.tbody_question_bank div {
  width: 100%;
  padding: 20px;
  text-align: center;
}

.tbody_question_bank .first {
  text-align: left;
}

.tbody_question_bank .last {
  border-left: 1px solid #ecf3f5;
}

.tbody_question_bank .second {
  border-left: 1px solid #ecf3f5;
}

.profile_detail_info {
  padding: 20px;
}

.title_detail {
  font-size: 14px;
  font-weight: 600;
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 20px;
}

.description_detail {
  font-size: 12px;
  font-weight: 400;
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 20px;
}

.profile_detail_info .second,
.profile_detail_info .third,
.profile_detail_info .fouth,
.profile_detail_info .last {
  text-align: left;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

/*********************************************************************************** USER SIGN IN PAGE START FROM HERE **********************************************************************/

/********************************* BELOW PART WILL BE COMMON IN ALL COMPONENT PAGES CSS *****************************************/

.signup_page {
  font-family: "Open Sans", sans-serif;
}

.signup_page .section1 {
  background-color: ghostwhite;
  width: 50%;
  float: left;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.signup_page .section1 .ims_logo {
  display: block;
  margin: 1em 0em 0em 3em;
}

.signup_page .section1 .ims_logo .logo {
  height: 120px;
  width: 120px;
}

.signup_page .section1 .main_area {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.signup_page .section1 .text {
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  width: 40%;
  height: 14vh;
  border-radius: 15px;
  font-size: small;
  margin-left: 15%;
  line-height: 2em;
}

.signup_page .section1 .text_area1 {
  margin-left: 10%;
  margin-top: 2%;
  padding-top: 1%;
  position: relative;
}

.signup_page .section1 .text_area2 {
  position: relative;
  margin-left: 33%;
  margin-top: -13.5%;
}

.signup_page .section1 .public {
  display: flex;
  justify-content: end;
  height: 30vh;
  margin-top: -6%;
}
.signup_page .section1 .public .public_img {
  height: 280px;
  width: 250px;
}

.signup_page .section1 .public .one {
  margin-right: 20%;
  margin-top: -10%;
}

.signup_page .section1 .public .two {
  margin-top: -10%;
  margin-right: 58%;
}

/****** Section 2 ******/

.signup_page .section2 {
  background-color: #fff;
  width: 50%;
  float: left;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.signup_page .section2 .nav_section {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 2%;
  margin-right: 10%;
}

.signup_page .section2 .nav_section a {
  text-decoration: none;
}

.signup_page .section2 .nav_section .nav_text {
  color: #454545;
  font-size: small;
  text-decoration: none;
  padding: 5px;
}

.signup_page .section2 .nav_section .nav_text:hover {
  color: black;
  font-weight: bold;
}

.signup_page .section2 .nav_section .fa_solid {
  color: lightgray;
  margin-right: 5px;
}

/*** USER SIGN IN AREA ***/

.signup_page .section2 .signup_form {
  height: 100%;
  display: flex;
  justify-content: center;
}

.signup_page .section2 .signup_form .user_form {
  background-color: ghostwhite;
  width: 70%;
  height: 87vh;
  margin-top: 3%;
  border-radius: 15px;
  box-shadow: 0px 8px 24px 0px #7090b026;
}

/**************************************************************** UPTO THIS PART ALL PAGES COMPONENT CSS WILL BE SAME ****************************************/

/**************************************************************** NOW BELOW THIS PART , ALL COMPONENT CSS WILL BE DIFFERENT (#REMEINDER#) ***********************/
.signup_page .section2 .signup_form .user_form .text_signin {
  width: fit-content;
  margin: 3em 1.5em;
}

/**** USER EMAIL AND PASSWORD AREA ***/

.signup_page .section2 .signup_form .user_form .user_info {
  margin: 3em 2.5em;
}
.signup_page .section2 .signup_form .user_info .mail_and_pass label {
  line-height: 2.5em;
  font-size: small;
}

.signup_page .section2 .signup_form .user_info .mail_and_pass label::after {
  content: " *";
  color: red;
}

.signup_page .section2 .user_info .mail_and_pass input {
  width: 85%;
  height: 5vh;
  display: flex;
  padding-left: 2em;
  border-radius: 12px;
  border: 1px solid lightgray;
}

.signup_page .section2 .signup_form .user_info .mail_and_pass input:focus {
  border: 1px solid lightgrey;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0) 0.1;
}

/**** FOREGT PASSWORD AREA AND OTHER OPTIONS ***/
.signup_page .section2 .signup_form .user_form .user_options {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: small;
  line-height: 3em;
}

.signup_page .section2 .signup_form .user_form .user_options .forget_pass {
  margin-left: 37%;
}

.signup_page .section2 .user_info input {
  width: 5%;
  height: 2.5vh;
}

/**** SIGN UP BUTTON AREA ***/
.signup_page .section2 .signup_form .signin_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
}

.signup_page .section2 .signup_form .signin_button .bt_signup {
  background-color: lightgray;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
  color: #fff;
  height: 8vh;
  width: 80%;
  cursor: pointer;
}
/****************************************************/

/************************************************************** USER  SIGN IN PAGE ENDS HERE **************************************************************************/

/****************************************** FORGET PASSWORD PAGE AFTER CLICKING ON FORGOT PASSWORD LINK IN SIGN IN PAGE STARTS FROM HERE *******************************************/
.signup_page .section2 .signup_form .user_form .forget_password {
  background-color: red;
  width: fit-content;
  margin: 3em 1.5em;
}

.signup_page .section2 .signup_form .user_form .forget_password .some_text {
  width: fit-content;
  font-size: small;
  font-weight: 400;
}

.signup_page .section2 .signup_form .user_form .user_pass_info {
  margin: 3em 2.5em;
  padding-top: 2.5em;
}

.signup_page
  .section2
  .signup_form
  .user_form
  .user_pass_info
  .setting_new_pass
  label::after {
  content: " *";
  color: red;
}

.signup_page
  .section2
  .signup_form
  .user_form
  .user_pass_info
  .setting_new_pass
  input {
  width: 85%;
  height: 5vh;
  display: flex;
  padding-left: 2em;
  border-radius: 12px;
  border: 1px solid lightgray;
  margin-top: 3%;
}

.signup_page .section2 .signup_form .user_form .forget_pass_bt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 43%;
}

.signup_page .section2 .signup_form .user_form .forget_pass_bt_signup {
  background-color: lightgray;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
  color: #fff;
  height: 8vh;
  width: 80%;
  cursor: pointer;
}
/********************************************* FORGET PASSWORD PAGE AFTER CLICKING ON FORGOT PASSWORD LINK IN SIGN IN PAGE ENDS HERE ************************************************/

/*********************************************** AFTER CLICKING ON EMAIL LINK -> SETTING NEW PASSOWRD PAGE APPEARS ******************************************************************/

/*************************************************************************** SETTING NEW PASSWORD PAGE STARTS FROM HERE *************************************************************/
.signup_page .section2 .signup_form .create_new_pass {
  width: fit-content;
  margin: 3em 1.5em;
}

.signup_page .section2 .signup_form .user_info {
  margin: 3em 2.5em;
}

.signup_page .section2 .signup_form .user_info .new_pass label {
  line-height: 3em;
  font-size: small;
}

.signup_page .section2 .signup_form .user_info .new_pass input {
  width: 85%;
  height: 5vh;
  display: flex;
  padding-left: 2em;
  border-radius: 12px;
  border: 1px solid lightgray;
  margin-top: 1%;
}

.signup_page .section2 .signup_form .setting_new_pass_signin_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
}

.signup_page
  .section2
  .signup_form
  .setting_new_pass_signin_button
  .bt_setting_new_pass_signup {
  background-color: lightgray;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
  color: #fff;
  height: 8vh;
  width: 80%;
  cursor: pointer;
}

/************************************************************ BELOW CODE WILL BE DYNAMICALLY CALLED ******************************************/

/***** BELOW CODE WILL ACTIVE ONLY WHEN NEW PASSWORD BAR REQUIEMENTS WILL NOT BE FULLFILLED *********/
.signup_page .section2 .signup_form .new_pass .hidden_alert {
  display: none;
}

/**** THIS WILL BE ACTIVE ONLY WHEN MIN LENGTH CONDITION FULL FILLED ****/
.signup_page
  .section2
  .signup_form
  .user_form
  .new_pass
  .hidden_alert
  .min_length {
  color: #26ab5b;
}

/**** THIS WILL BE ACTIVE ONLY WHEN STRONG PASSWORD CONDITION FULL FILLED ****/
.signup_page
  .section2
  .signup_form
  .user_form
  .new_pass
  .hidden_alert
  .strong_password {
  color: #26ab5b;
}

/**** THIS WILL BE ACTIVE ONLY WHEN BOTH THE CONDITION FULL FILLED ****/
.signup_page .section2 .signup_form .user_form .new_pass .hidden_alert li {
  color: #26ab5b;
}

/**** THIS WILL BE ACTIVE ONLY WHEN BOTH THE PASSWORDS MATCH ****/
.signup_page .section2 .signup_form .user_form .password_options {
  font-size: small;
  display: flex;
  align-items: center;
  line-height: 2em;
}

.signup_page
  .section2
  .signup_form
  .user_form
  .password_options
  .hidden_password_matchbox {
  margin-left: auto;
  margin-right: 8%;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #26ab5b;
  display: flex;
  align-items: center;
}

.signup_page
  .section2
  .signup_form
  .user_form
  .password_options
  .hidden_password_matchbox
  .tick {
  width: 15px;
  height: 15px;
  margin-right: 2px;
  background: linear-gradient(220.53deg, #9bf763 0%, #25ab5b 76.95%);
  border-radius: 50%;
  border: 1.5px solid #ffffff;
}

/****** BELOW CODE -> BACKGROUND OF BUTTON WILL BE CHANGED WHEN USER FILL ALL THE THE ABOVE GIVEN CREDENTIALS  ( WILL BE COMMON IN ALL CSS PAGES ) ***************/
/* .signup_page .section2 .signup_form .user_form .dynamic-button {
    background: #00abfb;
    box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
    border-radius: 16px;
  } */

/********************************************************************************** SETTING NEW PASSWORD PAGE ENDS HERE ************************************************************/

/********************************************** RESPONSIVE SM *************************/
@media only screen and (max-width: 768px) {
  .signup_page .section1 {
    transition: all 0.3s ease-in-out;
    display: none;
  }
  .signup_page .section2 {
    width: 100%;
    float: none;
    transition: all 0.4s ease;
  }

  .signup_page .section1 .text,
  .signup_page .section1 .public,
  .signup_page .section1 .public .public-img,
  .signup_page .section1 .public .one,
  .signup_page .section1 .public .two,
  .signup_page .section2 .signup_form .user_form {
    width: 100%;
    margin-left: 0;
    transition: all 0.4s ease;
  }
}

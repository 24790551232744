.irt_params {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  font-family: "IBM Plex Sans", sans-serif;
}

.irt_params .param_container {
  background-color: #fff;
  width: 95%;
  height: 85vh;
  margin-top: 20px;
  border-radius: 18px 18px 0px 0px;
  display: flex;
  box-shadow: 0px 8px 24px 0px #7090b026;
}



.irt_params .param_container .student_report_form .user_report_header {
  background-color: #fff;
  height: 84px;
  border-radius: 18px 18px 0px 0px;
  display: flex;
  padding: 5px;
  align-items: center;
}

.irt_params
  .param_container
  .student_report_form
  .user_report_header
  .header_content {
  display: flex;
  justify-content: space-around;
  margin-left: 2em;
  gap: 20px;
}

.irt_params
  .param_container
  .student_report_form
  .user_report_header
  .header_content
  .irt_title {
  font-weight: 500;
}

.irt_params
  .param_container
  .student_report_form
  .user_report_header
  .header_content
  .view_report {
  color: #00abfb;
  font-weight: 500;
  text-decoration: underline;
}

.irt_params
  .param_container
  .student_report_form
  .user_report_header
  .user_right_options {
  display: flex;
  align-items: center;
  margin-left: 40em;
  gap: 5px;
}

.irt_params
  .param_container
  .student_report_form
  .user_report_header
  .user_right_options
  .student_report_filter {
  cursor: pointer;
}

.irt_params
  .param_container
  .student_report_form
  .user_report_header
  .user_right_options
  .download_report_bt {
  background: #00abfb;
  border: 1px solid #00abfb;
  height: 56px;
  width: 212px;
  box-shadow: 0px 16px 40px 0px #7090b033;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #fff;
  border-radius: 10px;
  margin-left: 2em;
  gap: 10px;
  cursor: pointer;
}

.irt_params .param_container .student_report_form .user_report_table {
  height: 100%;
  width: 100%;
  display: block;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.irt_params .param_container .student_report_form .user_report_table table {
  border-collapse: collapse;
  width: 100%;
  font-family: "IBM Plex Sans", sans-serif;
}

.irt_params .param_container .student_report_form .user_report_table tr {
  border-bottom: 1px solid #ecf3f5;
}

.irt_params .param_container .student_report_form .user_report_table th {
  padding: 10px;
  background-color: #e9f8ff;
  font-weight: 600;
  font-size: 12px;
  width: 14.2855%;
}

.irt_params .param_container .student_report_form .user_report_table td {
  padding: 30px;
  font-size: 12px;
  width: 14.28%;
  text-align: center;
}


.student_report_form {
  width: 100%;
}

















/* __________________________ QDR __________________________ */


.qdr_params {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  font-family: "IBM Plex Sans", sans-serif;
}

.qdr_params .param_container {
  background-color: #fff;
  width: 95%;
  height: 85vh;
  margin-top: 20px;
  border-radius: 18px 18px 0px 0px;
  display: flex;
  box-shadow: 0px 8px 24px 0px #7090b026;
}



.qdr_params .param_container .student_report_form .user_report_header {
  background-color: #fff;
  height: 84px;
  border-radius: 18px 18px 0px 0px;
  display: flex;
  padding: 5px;
  align-items: center;
}

.qdr_params
  .param_container
  .student_report_form
  .user_report_header
  .header_content {
  display: flex;
  justify-content: space-around;
  margin-left: 2em;
  gap: 20px;
}

.qdr_params
  .param_container
  .student_report_form
  .user_report_header
  .header_content
  .qdr_title {
  font-weight: 500;
}

.qdr_params
  .param_container
  .student_report_form
  .user_report_header
  .header_content
  .view_report {
  color: #00abfb;
  font-weight: 500;
  text-decoration: underline;
}

.qdr_params
  .param_container
  .student_report_form
  .user_report_header
  .user_right_options {
  display: flex;
  align-items: center;
  margin-left: 40em;
  gap: 5px;
}

.qdr_params
  .param_container
  .student_report_form
  .user_report_header
  .user_right_options
  .student_report_filter {
  cursor: pointer;
}

.qdr_params
  .param_container
  .student_report_form
  .user_report_header
  .user_right_options
  .download_report_bt {
  background: #00abfb;
  border: 1px solid #00abfb;
  height: 56px;
  width: 212px;
  box-shadow: 0px 16px 40px 0px #7090b033;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #fff;
  border-radius: 10px;
  margin-left: 2em;
  gap: 10px;
  cursor: pointer;
}

.qdr_params .param_container .student_report_form .user_report_table {
  height: 100%;
  width: 100%;
  display: block;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.qdr_params .param_container .student_report_form .user_report_table table {
  border-collapse: collapse;
  width: 100%;
  font-family: "IBM Plex Sans", sans-serif;
}

.qdr_params .param_container .student_report_form .user_report_table tr {
  border-bottom: 1px solid #ecf3f5;
  overflow-x: scroll;
}

.qdr_params .param_container .student_report_form .user_report_table th {
  padding: 10px;
  background-color: #e9f8ff;
  font-weight: 600;
  font-size: 12px;
  width: 14.2855%;
}
.qdr_params .param_container .student_report_form .user_report_table .all{
  width: 100%;
  text-align: left;
  padding-left: 35px;
}

.qdr_params .param_container .student_report_form .user_report_table td {
  /* padding: 15px 50px; */
  font-size: 15px;
  width: 14.28%;
  text-align: left;
  cursor: pointer;
}


.qdr_params .param_container .student_report_form .user_report_table td .subject{
  color: #00abfb;
  font-weight: 500;
  width: 100%;
  padding: 15px 0pc 15px 50px;
  border-bottom: 1px solid rgb(202, 201, 201);
}

.qdr_params .param_container .student_report_form .user_report_table td .subject b{
  font-weight: 500;
  margin-left: 10px;
}

.qdr_params .param_container .student_report_form .user_report_table td .area{
  color: #232425;
  width: 100%;
  padding: 15px 0px 15px 100px;
  border-bottom: 1px solid rgb(202, 201, 201);
}

.qdr_params .param_container .student_report_form .user_report_table td .area b{
  font-weight: 500;
  margin-left: 10px;
}


.qdr_params .param_container .student_report_form .user_report_table td .topic{
  color: #232425;
  width: 100%;
  padding: 15px 0px 15px 150px;
  border-bottom: 1px solid rgb(202, 201, 201);
}

.qdr_params .param_container .student_report_form .user_report_table td .topic b{
  font-weight: 500;
  margin-left: 10px;
}


.qdr_params .param_container .student_report_form .user_report_table td .sub_topic{
  color: #232425;
  width: 100%;
  padding: 15px 0px 15px 200px;
  border-bottom: 1px solid rgb(202, 201, 201);
}

.qdr_params .param_container .student_report_form .user_report_table td .sub_topic b{
  font-weight: 500;
  margin-left: 10px;
}



.qdr_params .param_container .student_report_form .user_report_table td .number{
  text-align: center;
  font-size: 12px;
  padding: 17px 50px;
  border-bottom: 1px solid rgb(202, 201, 201);
  }
.create_question {
  padding-left: 3%;
  padding-top: 2%;
}

.create_question .create_title {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  font-family: "IBM Plex Sans", sans-serif;
  margin-bottom: 1%;
}

.bound {
  /* background-color: khaki; */
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
}

.bound .templateGroup {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  margin-left: 5px;
}

.bound .templateGroup select {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  outline: none;
  border: none;
  width: 370px;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
}

.bound .templateGroup .textInput {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  outline: none;
  border: none;
  width: 470px;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
}

.bound .verbalBox {
  width: 376px;
}

.bound .templateDrop .varc {
  width: 178px;
}

.bound .templateDrop .templateDrop_item .templateGroup .fullBgmarks {
  background: #ecf3f5;

  padding-right: 10px;
}

/* .create_question .create_question_screen_list{
    background-color: red;
  } */
.create_question .create_question_screen_list span {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  font-family: "IBM Plex Sans", sans-serif;
  color: #c7ccd1;
}

.create_question .create_question_screen_list .active {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  font-family: "IBM Plex Sans", sans-serif;
  color: #00abfb;
}

.question_ID {
  padding-left: 3%;
  width: 85%;
}

.question_ID .title {
  width: 100%;
  height: 68px;
  background-color: #ffffff;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 2%;
  margin-top: 2%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  box-shadow: 0px 0px 10px rgb(238, 235, 235);
}

.question_ID .title span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  font-family: "IBM Plex Sans", sans-serif;
}

.question_ID .select_question {
  width: 100%;
  background-color: #ffffff;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding-left: 2%;
  padding-top: 1.5%;
  margin-top: 2px;
  padding-bottom: 5%;
  overflow-y: scroll;
  max-height: 400px;
  height: 100%;
}

.question_ID .select_question .top_bar .top_bar_title span {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  font-family: "IBM Plex Sans", sans-serif;
}

.select_question .list {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-top: 3.2%;
  padding-bottom: 3.2%;
}

.select_question .list .box {
  flex: 0 0 calc(33.333% - 30px);
  width: 100%;
  height: 140px;
  box-shadow: 0px 0px 10px rgb(224 213 213);
  border-radius: 20px;
  position: relative;
}

.select_question .list .active_box {
  flex: 0 0 calc(33.333% - 30px);
  width: 340px;
  height: 140px;
  box-shadow: 0px 0px 6px #00abfb;
  border-radius: 20px;
  position: relative;
}

.select_question .list .box .title_list_box,
.select_question .list .active_box .title_list_box {
  left: 15px;
  position: absolute;
}

.select_question .list .box .question_list_box,
.select_question .list .active_box .question_list_box {
  right: 20px;
  bottom: 0px;
  position: absolute;
}

.select_question .list .box .question_list_box p,
.select_question .list .active_box .question_list_box p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  font-family: "IBM Plex Sans", sans-serif;
}

.select_question .list .box .question_list_box p span,
.select_question .list .active_box .question_list_box p span {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  font-family: "IBM Plex Sans", sans-serif;
}

.select_question .list .box .smallBall,
.select_question .list .active_box .smallBall {
  right: 45px;
  position: absolute;
}

.select_question .list .box .largeBall,
.select_question .list .active_box .largeBall {
  right: 0px;
  position: absolute;
}

.select_question .list .box .largeBall svg,
.select_question .list .active_box .largeBall svg {
  border-top-right-radius: 20px;
}

.saveButton_questionBank {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
}

.saveButton_questionBank .cancel {
  text-decoration: underline;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: "IBM Plex Sans", sans-serif;
}

.select_question .info {
  display: flex;
  flex-wrap: wrap;
  gap: 56px;
  padding-top: 3.2%;
  padding-bottom: 3.2%;
}

.select_question .free_tag {
  margin-bottom: 30px;
}

.select_question .free_tag .tag .tag_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
}

.select_question .free_tag .tag .tag_desc {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  color: #212529;
  margin-top: 10px;
}

.select_question .free_tag .tag_search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 0px solid #ffffff;
  box-shadow: 0px 0px 10px rgb(226, 218, 218);
  max-width: 574px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.select_question .free_tag .tag_search .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80px;
  width: 100%;
}

.select_question .free_tag .tag_search .tag_icon span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 10px;
}

.select_question .free_tag .tag_search .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  display: flex;
  padding-left: 20px;
  width: 100%;
  position: relative;
}

.select_question .free_tag .tag_search .input_search input,
.select_question .free_tag .tag_search .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.select_question .free_tag .tag_search .cancel_button {
  float: right;
}

.select_question .free_tag .tag_search .cancel_button button {
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list_input_primary {
  position: absolute;
  top: 55px;
  border: 1px solid white;
  background-color: white;
  box-shadow: 0px 5px 10px rgb(231, 226, 226);
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  z-index: 50;
}

.list_input_primary div {
  padding: 8px 5px;
}

.list_input_primary div:hover span {
  background-color: #00abfb;
  color: white;
}

.list_input_primary div span {
  border-radius: 50px;
  background-color: #f4f5f6;
  padding: 5px 22px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.input_search span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
  align-items: center;
  display: inline-flex;
}

.Secondary_tag .tag .tag_search .input_search .selected_tags {
  border-radius: 50px;
  background-color: #f4f5f6;
  padding: 2px 22px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.free_tag .tag .tag_search .input_search .selected_tags {
  border-radius: 50px;
  background-color: #f4f5f6;
  padding: 2px 22px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.select_question .Secondary_tag {
  margin-bottom: 30px;
}

.select_question .Secondary_tag .tag .tag_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
}

.select_question .Secondary_tag .tag .tag_desc {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  color: #212529;
  margin-top: 10px;
}

.select_question .Secondary_tag .tag_search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 0px solid #ffffff;
  box-shadow: 0px 0px 10px rgb(226, 218, 218);
  max-width: 574px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.select_question .Secondary_tag .tag_search .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80px;
  width: 100%;
}

.select_question .Secondary_tag .tag_search .tag_icon span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 10px;
}

.select_question .Secondary_tag .tag_search .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  display: flex;

  width: 100%;
  position: relative;
}

.select_question .Secondary_tag .tag_search .input_search input,
.select_question .Secondary_tag .tag_search .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  padding-left: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.select_question .Secondary_tag .tag_search .cancel_button {
  float: right;
}

.select_question .Secondary_tag .tag_search .cancel_button button {
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Primry tag*/

.select_question .primary_tag {
  margin-bottom: 30px;
}

.select_question .primary_tag .tag .tag_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
}

.select_question .primary_tag .tag .tag_desc {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  color: #212529;
  margin-top: 10px;
}

.select_question .primary_tag .tag_search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 0px solid #ffffff;
  box-shadow: 0px 0px 10px rgb(226, 218, 218);
  max-width: 574px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.select_question .primary_tag .tag_search .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80px;
  width: 100%;
}

.select_question .primary_tag .tag_search .tag_icon span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 10px;
}

.select_question .primary_tag .tag_search .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  display: flex;

  width: 100%;
  position: relative;
}

.select_question .primary_tag .tag_search .input_search input,
.select_question .primary_tag .tag_search .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
}

.select_question .primary_tag .tag_search .cancel_button {
  float: right;
}

.select_question .primary_tag .tag_search .cancel_button button {
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/** yeh clear nhai hai***/
/* Remove the clear (cross) icon from the search input */
#searchInput::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

/* Remove the clear (cross) icon from the search input for Mozilla Firefox */
#searchInput::-webkit-search-clear-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

/*** yaha tak **/

.select_question .info .format {
  max-height: 56px;
  max-width: 305px;
  width: 100%;
  height: 100%;
}

.select_question .info .format_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
}

.select_question .info .format_choice {
  display: flex;
  margin-top: 16px;
  gap: 26px;
}

.select_question .info .format_choice div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select_question .info .format_choice label {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  font-family: "IBM Plex Sans", sans-serif;
}

.select_question .info .format_choice input {
  margin: 0px;
  margin-right: 6px;
}

.select_question .Question_Direction {
  width: 60%;
  margin-top: 40px;
}

.select_question .Question_Direction .tox_tinymce {
  height: 200px !important;
}

.select_question .Question_Direction p {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 5px;
}

/**** send for review***/
.select_question .send_for_review {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.send_for_review .top {
  text-align: center;
  /* border-bottom: 2px solid black; */
  /* padding-bottom: 5%; */
}

.send_for_review .create .line_div {
  padding: 4% 10%;
}

.send_for_review .create .line_div .line {
  border-top: 2px solid #ecf3f5;
}

.send_for_review .create {
  text-align: center;
  /* margin-top: 6%; */
}

.send_for_review .create h4 {
  margin: 0px;
}

/*** select box type ***/

.select_question .question_type_box {
  border: 1px solid white;
  box-shadow: 0px 0px 10px #ece4e4;
  width: 90%;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
}

.select_question .question_type_box .question_type_title {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}

.select_question .question_type_box .question_type_title svg {
  float: right;
}

.select_question .question_type_box .category {
  display: flex;
  gap: 10px;
  margin-top: 40px;
  height: 90%;
}

.select_question .question_type_box .category .sub_category {
  padding-right: 0px;
  border-right: 1px solid gray;
  width: 18%;
}

.select_question .question_type_box .category .sub_category div {
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  font-family: "IBM Plex Sans", sans-serif;
  margin-bottom: 15px;
}

.select_question .question_type_box .category .name_category {
  width: 82%;
  position: relative;
  padding-left: 20px;
}

.select_question .question_type_box .category .name_category .selection_box {
  display: flex;
  gap: 20px;
  margin-bottom: 5%;
}

.select_question .question_type_box .category .name_category .select_type p {
  /* border: 2px solid black; */
  margin-top: 0px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-family: "IBM Plex Sans", sans-serif;
  color: #212529;
}

.select_question .question_type_box .category .name_category .select_type .select_box_type {
  border: 1px solid #C7CCD1;
  border: 1px solid #C7CCD1;
  padding: 13px 20px;

}


.select_question .question_type_box .category .name_category .select_type .select_box_type p {
  line-height: 14px;
  font-size: 10px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #212529;
  margin-top: 0px;
}

.select_box_type div {
  display: flex;
  margin-bottom: 5px;
  /* justify-content: center; */
  align-items: center;

}

.select_box_type div label {
  line-height: 14px;
  font-size: 10px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
  margin-left: 5px;
}

.select_box_type div input {
  margin: 3px;
}

.saveButton_questionCreation {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
  position: relative;
  z-index: 50;
}

.saveButton_questionCreation .cancel {
  text-decoration: underline;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: "IBM Plex Sans", sans-serif;
}

.select_question .question_creating {
  border: 1px solid white;
  box-shadow: 0px 0px 10px #ece4e4;
  width: 60%;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #00abfb;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  padding-right: 10px;
}

.select_question .question_creating .question_creating_title {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}

.select_question .question_creating .question_creating_title svg {
  float: right;
}

.select_question .passage_text {
  width: 60%;
  margin-bottom: 40px;
}

.select_question .passage_text .tox_tinymce {
  height: 200px !important;
}

.select_question .passage_text p {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 5px;
}

.select_question .enter_answer {
  margin-top: 40px;
  display: flex;
  align-items: center;
  width: 59.9%;
}

.select_question .enter_answer .title_detail {
  width: 50%;
}

.select_question .enter_answer .random_text {
  text-align: right;
  width: 50%;
}

.select_question .enter_answer .title_enter_answer {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.select_question .enter_answer .title_enter_desc {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 0px;
}

.tinymce_text {
  margin-bottom: 50px;
}

/* Editor width can be changed from here */
.select_question .compose_text {
  width: 60%;
  margin-top: 40px;
}
.select_question .explanation_text {
  width: 60%;
  margin-top: 40px;
}

.select_question .compose_text .tox_tinymce {
  height: 200px !important;
}

.select_question .compose_text p {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 5px;
}

.explanation_text p {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 5px;
}

.select_question .Answer_text {
  width: 70%;
  margin-top: 40px;
}

.select_question .Answer_text .tox_tinymce {
  height: 200px !important;
}

.select_question .Answer_text p {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 5px;
  max-width: 85%;
}

.select_question .Answer_text .select {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Answer_text .editor_box {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 100%;
}

.Answer_text .editor_box .close_answer {
  display: flex;
  align-items: center;
  background-color: #f6f7fb;
  border-radius: 10px;
  padding: 8px 12px;
}

.Answer_text .editor_box .editor {
  width: 85%;
}

.Answer_text .editor_box .close_answer span {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-left: 8px;
}

.Add_answer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.Add_answer span {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 8px;
  color: #00abfb;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.Answer_text .editor_box {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 100%;
}

.Answer_text .editor_box .close_answer {
  display: flex;
  align-items: center;
  background-color: #f6f7fb;
  border-radius: 10px;
  padding: 8px 12px;
}

.Answer_text .editor_box .editor {
  width: 85%;
}

.Answer_text .editor_box .close_answer span {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-left: 8px;
}

.explanation_text .explanation_video {
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 10px;
}

.explanation_text .explanation_video input,
.explanation_text .explanation_video input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  padding: 10px 10px;
}

.select_question .explanation_line {
  width: 60%;
  margin-top: 40pxmce;
}

.select_question .explanation_line .line {
  border: 2px solid #ecf3f5;
}

.select_question .correctAnswer {
  width: 60%;
  margin-top: 40px;
}

.select_question .correctAnswer .tox_tinymce {
  height: 200px !important;
}

.select_question .correctAnswer p {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 5px;
}

.select_question .correctAnswer textarea {
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  width: 97%;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.select_question .correctAnswer_format {
  width: 60%;
  margin-top: 40px;
}

.select_question .correctAnswer_format p {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.select_question .correctAnswer_format .range_calculate {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 40px;
}

.select_question .correctAnswer_format .range_calculate .range {
  display: flex;
  align-items: center;
  width: 85%;
  gap: 40px;
}

.select_question .correctAnswer_format .range_calculate .range .from {
  width: 100%;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.select_question .correctAnswer_format .range_calculate .range .from input {
  width: 100%;
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin-top: 2%;
}

.select_question .correctAnswer_format .range_calculate .range .to {
  width: 100%;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.select_question .correctAnswer_format .range_calculate .range .to input {
  width: 100%;
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin-top: 2%;
}

.select_question .correctAnswer_range {
  width: 60%;
  margin-top: 40px;
}

.select_question .correctAnswer_range p {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.select_question .correctAnswer_range .range_calculate {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 40px;
}

.select_question .correctAnswer_range .range_calculate .range {
  display: flex;
  align-items: center;
  width: 65%;
  gap: 40px;
}

.select_question .correctAnswer_range .range_calculate .rangetext {
  width: 34%;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.select_question .correctAnswer_range .range_calculate .rangetext input {
  width: 94%;
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin-top: 2%;
}

.select_question .correctAnswer_range .range_calculate .range .from {
  width: 100%;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.select_question .correctAnswer_range .range_calculate .range .from input {
  width: 100%;
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin-top: 2%;
}

.select_question .correctAnswer_range .range_calculate .range .to {
  width: 100%;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.select_question .correctAnswer_range .range_calculate .range .to input {
  width: 100%;
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin-top: 2%;
}

.select_question .answertype {
  width: 60%;
  margin-top: 40px;
}

.select_question .answertype p {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 5px;
}

.select_question .answertype select {
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  width: 30%;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

/*** MULTIQUESTION FILE **/

.multiquestion {
  display: flex;
  justify-content: start;
  gap: 10px;
}

.questions_remove {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 63.5%;
  margin: 20px 0px;
}

.questions_remove .titles,
.remove {
  width: 50%;
}

.remove_question {
  display: flex;
  align-items: center;
  background-color: #f6f7fb;
  border-radius: 10px;
  padding: 8px 12px;
  float: right;
}

.question_identifier {
  width: 97%;
  height: 110px;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  font-family: "IBM Plex Sans", sans-serif;
  color: #0f1010;
}

.blue_button {
  background-color: #00abfb;
  color: white;
  width: 197px;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-family: "IBM Plex Sans", sans-serif;
  cursor: pointer;
}

.blue_button_disable {
  background-color: #00abfb88;
  color: white;
  width: 197px;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-family: "IBM Plex Sans", sans-serif;
  cursor: not-allowed;
}

/* ______________________ DRAG  ______________________ */
.container {
  margin-top: 1rem;
  display: flex;
  width: 35%;
  gap: 10px;
}

.draggable {
  padding: 1rem;
  cursor: move;
  width: 50%;
}

.draggable.dragging {
  opacity: 0.5;
}

.multiquestionbutton {
  background-color: #00abfb;
  color: white;
  width: 185px;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-family: "IBM Plex Sans", sans-serif;
  box-shadow: 0px 5px 10px #e8dcdc;
  cursor: pointer;
}

.multiquestionbuttonBlue {
  background-color: #ffffff;
  color: rgb(9, 7, 7);
  width: 185px;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-family: "IBM Plex Sans", sans-serif;
  box-shadow: 0px 5px 10px #e8dcdc;
  cursor: pointer;
}

.red_mendantory {
  color: red;
}

.checked_full {
  background-color: #e9f8ff;
}

/*** MASTERS CATEGORY **/

.master_category {
  width: 70%;
}

.Master_div {
  display: flex;
  justify-content: center;
}

/* _______________________ question-bank ____________________________ */
.question_bank_table_top {
  padding: 30px 30px;
}

.question_bank {
  width: 100%;
  /* padding: 30px 30px; */
}

.question_bank .question_bank_report {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.question_bank .question_bank_report .results {
  width: 65%;
  height: 108px;
  gap: 20px;
  display: inline-flex;
}

.question_bank .question_bank_report .results .approved {
  max-width: 277px;
  width: 100%;
  background-color: #ffffff;
  height: 108px;
  outline: 0.5px solid #26ab5b;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question_bank .question_bank_report .results .approved .box {
  width: 219.27px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 7px;
}

.question_bank .question_bank_report .results .approved .box .icons {
  width: 55.8px;
  height: 56px;
  background-color: #e9fcf1;

  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question_bank .question_bank_report .results .approved .box .score {
  width: 145.47px;
  height: 60px;
}

.question_bank .question_bank_report .results .approved .box .score p {
  margin: 0px;
  font-size: 16px;
  color: #212529;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 20px;
}

.question_bank .question_bank_report .results .approved .box .score span {
  margin: 0px;
  font-size: 28px;
  color: #212529;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  line-height: 32px;
}

.question_bank .question_bank_report .results .Rejected {
  max-width: 277px;
  width: 100%;
  background-color: #ffffff;
  height: 108px;
  outline: 0.5px solid #ff5757;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question_bank .question_bank_report .results .Rejected .box {
  width: 219.27px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 7px;
}

.question_bank .question_bank_report .results .Rejected .box .icons {
  width: 55.8px;
  height: 56px;
  background-color: #fff2f1;

  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question_bank .question_bank_report .results .Rejected .box .score {
  width: 145.47px;
  height: 60px;
}

.question_bank .question_bank_report .results .Rejected .box .score p {
  margin: 0px;
  font-size: 16px;
  color: #212529;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 20px;
}

.question_bank .question_bank_report .results .Rejected .box .score span {
  margin: 0px;
  font-size: 28px;
  color: #212529;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  line-height: 32px;
}

.question_bank .question_bank_report .results .Review {
  max-width: 277px;
  width: 100%;
  background-color: #ffffff;
  height: 108px;
  outline: 0.5px solid #ffcf00;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question_bank .question_bank_report .results .Review .box {
  width: 219.27px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 7px;
}

.question_bank .question_bank_report .results .Review .box .icons {
  width: 55.8px;
  height: 56px;
  background-color: #fff9e8;

  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question_bank .question_bank_report .results .Review .box .score {
  width: 145.47px;
  height: 60px;
}

.question_bank .question_bank_report .results .Review .box .score p {
  margin: 0px;
  font-size: 16px;
  color: #212529;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 20px;
}

.question_bank .question_bank_report .results .Review .box .score span {
  margin: 0px;
  font-size: 28px;
  color: #212529;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  line-height: 32px;
}

.add_new_question {
  background-color: #00abfb;
  max-width: 228px;
  width: 100%;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_new_question span {
  font-size: 16px;
  color: #ffffff;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 20px;
  margin-left: 10px;
}

.add_question {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 108px;
}

.table_content_report {
  width: 100%;
  overflow-y: scroll;
  background-color: #ffffff;
  border-radius: 20px;
  margin-top: 2%;
  height: 380px;
}

.table_content_report .top_content_table {
  width: 100%;
  height: 77px;
  display: flex;
  align-items: center;
}

.table_content_report .top_content_table .table_title {
  height: 31px;
  gap: 24px;
  display: flex;
  width: 30%;
  margin-left: 20px;
}

.table_content_report .top_content_table .table_title span {
  font-size: 16px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 20px;
}

/* .table_content_report .top_content_table .table_title span:hover {
  border-bottom: 5px solid #00ABFB;
} */

/* .table_content_report .top_content_table{
      
    }
    .table_content_report .top_content_table{
      
    } */

.table_content_report .top_content_table .search_listing {
  width: 50%;
  height: 40px;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #eceff1;
}

.search_listing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid #d9d1d1; */
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  /* max-width: 574px; */
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search_listing .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  width: 100%;
}

.search_listing .tag_icon span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
}

.search_listing .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  display: flex;
  width: 100%;
  position: relative;
}

.search_listing .input_search input,
.search_listing .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
}

.search_listing .cancel_button {
  float: right;
}

.search_listing .cancel_button button {
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search_listing .tag_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
}

.search_listing .input_searchs {
  height: 100%;
  display: flex;
  width: 100%;
  position: relative;
}

.search_listing .input_searchs input,
.search_listing .input_searchs input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 0px;
}

.filter {
  width: 20%;
  height: 24px;
  display: flex;
  justify-content: end;
  margin-right: 20px;
}

.filter div {
  display: inline-flex;
  align-items: center;
}

.filter div span {
  font-size: 14px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
}

.sub_head_content_table table tbody tr td {
  border-bottom: 2px solid #f6f7fb !important;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 13px;
  padding-right: 13px;
}

.table_content_report .sub_head_content_table table {
  border-spacing: 0px !important;
  /* max-width: 1158px; */
  width: 100%;
}

.table_content_report .sub_head_content_table table thead {
  background-color: #e9f8ff;
  height: 41px;
  text-align: center;
  font-size: 12px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 14px;
}

.table_content_report .sub_head_content_table table thead .very_first {
  text-align: left !important;
  padding-left: 26px;
  height: 28px;
  /* width: 147px; */
}

.table_content_report .sub_head_content_table table thead .first {
  height: 28px;
  /* width: 110px; */
}

.table_content_report .sub_head_content_table table thead .second {
  height: 28px;
  /* width: 86px; */
}

.table_content_report .sub_head_content_table table thead .third {
  height: 28px;
  /* width: 105px; */
}

.table_content_report .sub_head_content_table table thead .fourth {
  height: 28px;
  /* width: 66px; */
}

.table_content_report .sub_head_content_table table thead .fifth {
  height: 28px;
  /* width: 74px; */
}

.table_content_report .sub_head_content_table table thead th span {
  margin-right: 4px;
}

.question_id_tag {
  /* width: 420px; */
  /* height: 54px; */
  display: inline-flex;
  margin-left: 13px;
  align-items: center;
}

.question_id_tag .id {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-decoration: underline;
  line-height: 16px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  margin-right: 25px;
}

.question_id_tag .tag {
  /* width: 349px; */
  text-align: center;
}

.question_id_tag .tag p {
  font-size: 12px;
  line-height: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  /* height: 14px; */
  margin-top: 0px;
}

.question_id_tag .tag span {
  font-size: 10px;
  line-height: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  height: 14px;
  background-color: #e9f8ff;
  border-radius: 50px;
}

.general_button {
  width: 90px;
  height: 28px;
  border-radius: 50px;
  background-color: #f4f5f6;
  border: none;
  font-size: 10px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.general_tag {
  display: flex;
  gap: 10px;
  justify-content: center;
  /* margin-left: 26px; */
}

.author_tag {
  font-size: 12px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  /* margin-left: 26px; */
}

.created_date,
.lod {
  font-size: 12px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  /* margin-left: 26px; */
}

.status {
  /* margin-left: 26px; */
  /* display: flex; */
  /* justify-content: center; */
  text-align: center;
}

.action {
  /* margin-left: 26px; */
  margin-right: 26px;
  position: relative;
}

.status span {
  background-color: #fff9e8;
  color: #ffcf00;
  width: 62px;
  height: 20px;
  border: 0.3px solid #ffcf00;
  border-radius: 4px;
  font-size: 10px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  padding: 4px 8px 4px 8px;
}

.underline_active {
  border-bottom: 5px solid #00abfb;
}

.action_button {
  box-shadow: 0px 0px 10px #ddd4d4;
  position: absolute;
  left: -105px;
  top: 28px;
  width: 90px;
  padding: 7px 15px;
  border-radius: 10px;
  z-index: 60;
}

.action_button .edit {
  padding: 5px 0px;
}

.action_button .duplicate {
  padding: 5px 0px;
}

/**** PAGINATION**/

.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 20px;
  margin-top: 20px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-family: "IBM Plex Sans", sans-serif;
}

.pagination .number {
  display: flex;
  text-transform: capitalize;
  margin-right: 10px;
  align-items: center;
}

.pagination .change {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.pagination .line {
  display: flex;
  align-items: center;
}

.pagination .change button {
  border: none;
  border-radius: 10px;
  align-items: center;
  display: flex;
  padding: 10px;
}

.pagination .change .current {
  margin: 10px;
}

.currentpage {
  border: none;
  border-radius: 10px;
  align-items: center;
  display: flex;
  padding: 10px;
}

.pointer {
  cursor: pointer;
}


.back_button {
  border: none;
  padding: 15px 15px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
  @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap');
  /* shivam css  */

  body {
    text-transform: capitalize;
  }

  :root {
    --e-global-color-white: #fff;
    --e-global-color-black: #000;
    --e-global-color-purple-dark: #1C1635;
    --e-global-color-purple-light-gradient: linear-gradient(188.58deg, #35227E 0.28%, #5532DF 88.79%);
    --e-global-color-yellow: #FFE600;
    --e-global-color-blue: #00ABFB;
    --e-global-color-grey-dark: #6E7E81;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 5px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #E0DADA;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #DADCE0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #DADCE0;
    border-radius: 10px;
  }

  body {
    font-family: 'Roboto', sans-serif;
  }

  .aic {
    align-items: center;
  }

  .jcsb {
    justify-content: space-between;
  }

  a {
    text-decoration: none;
    transition: 0.3s ease;
  }

  svg path {
    transition: 0.3s ease;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  #dashboard {
    background: #F8F9FB;
    height: 100%;
    width: 100%;
    /* max-width: 1440px;
    max-height: 768px; */
    position: fixed;
  }

  /* _________________ SIDE BAR CSS ___________________________  */
  #side_bar .side_bar_contents {
    overflow-x: hidden;
    height: 100%;

  }

  #side_bar {
    position: fixed;
    height: 100%;
    width: 12%;
    /* max-width: 172px; */
    background: #FFFFFF;
    box-shadow: 0px 4px 51px rgba(0, 0, 0, 0.14);
    top: 0;
    left: 0;
    transition: all 0.5s;
  }

  #side_bar.hide {
    left: -100%;
  }

  #side_bar .side_bar_contents .menu_top_area {
    height: 18%;
    justify-content: center;
    display: flex;
    align-items: center;
  }


  #side_bar .side_bar_contents .menu_bottom_area {
    height: 10%;
    display: flex;
    align-items: flex-end;
  }

  #side_bar .side_bar_contents .menu_bottom_area .menu_bottom_area-sub {
    height: 80px;
    width: 172px;
    align-items: center;
    display: flex;
  }

  #side_bar .brand_logo {
    margin-bottom: 30px;
  }

  #side_bar .user_profile .user_area .user_info .avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    /* background: url(../img/user_avatar.png); */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  #side_bar .user_profile .user_area .user_info .details {
    margin-left: 10px;
  }

  #side_bar .user_profile .user_area .user_info .details span.user_name {
    font-size: 14px;
    line-height: 16px;
    font-family: 'inter-sb';
    color: #ffffff;
    margin-bottom: 4px;
    display: block;
    text-transform: capitalize;
  }

  #side_bar .user_profile .user_area .user_info .details span.user_type {
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
    display: block;
    text-transform: capitalize;
  }

  #side_bar .user_profile .user_area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }

  #side_bar .user_profile .user_area .user_info {
    display: flex;
    align-items: center;
  }

  #side_bar .user_profile:hover a svg path {
    fill: #3B82F6;
  }

  #side_bar .user_profile a svg path {
    fill: #BBBBBC;
  }

  #side_bar .menu_title {
    padding: 50px 30px 0;
    margin-bottom: 22px;
  }

  #side_bar .menu_title span.title_menu {
    display: block;
    text-transform: uppercase;
    color: #BBBBBC;
    font-family: 'inter-sb';
    font-size: 14px;
    line-height: 21px;
  }

  #side_bar ul.main-menus {
    padding: 0 0px;
  }

  #side_bar .menus {
    padding: 0px 0px 0;
    overflow-y: auto;
    height: 72%;
    position: relative;
    overflow-x: hidden;
  }

  #side_bar ul.main-menus li .menu-item-content {
    display: flex;
    padding: 16px;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  #side_bar ul.main-menus li:last-child {
    margin-bottom: 0;
    margin-top: 0px;
  }

  #side_bar ul.main-menus-top {
    /* margin-bottom: 50px; */
    height: calc(100vh - 227px);
    overflow: auto;
  }

  #side_bar ul.main-menus-top::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 10px;
  }

  #side_bar ul.main-menus-top .accordian-custom-button {
    background-color: transparent;
    border-radius: 0;
    color: #fff;
    padding: 0;
  }

  #side_bar ul.main-menus-top .accordian-custom-button svg {
    margin-right: 20px;
  }



  #side_bar ul.main-menus li .menu_item {
    display: flex;
    align-items: center;
  }

  #side_bar ul.main-menus li .menu_item svg {
    margin-right: 19px;
  }

  #side_bar ul.main-menus li svg path {
    fill: #ffffff;
  }

  #side_bar ul.main-menus li:hover svg path,
  #side_bar ul.main-menus li:hover .menu_item span.menu_name {
    fill: none;
    color: var(--e-global-color-blue);
  }

  /* #side_bar ul.main-menus li .active svg path {
    fill: var(--e-global-color-blue) !important;
  } */

  #side_bar ul.main-menus li .active .menu_item span.menu_name {
    color: var(--e-global-color-blue) !important;
  }

  #side_bar ul.main-menus li .menu_item span.menu_name {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #272727;
    font-family: 'IBM Plex Sans', sans-serif;
    transition: 0.3s ease;
    text-transform: capitalize;
  }

  #side_bar ul.main-menus li .sub_menu_items ul.sub_menu {
    margin-bottom: 30px;
  }

  #side_bar ul.main-menus li .sub_menu_items ul.sub_menu li {
    margin: 0 !important;
  }

  #side_bar ul.main-menus li .sub_menu_items ul.sub_menu li a {
    display: flex;
    align-items: center;
    color: #BBBBBC;
    transition: 0.3s ease;
  }

  #side_bar ul.main-menus li .sub_menu_items ul.sub_menu li a svg {
    margin-right: 11px;
  }

  #side_bar ul.main-menus li .sub_menu_items ul.sub_menu li a svg path {
    fill: #BBBBBC !important;
  }

  #side_bar ul.main-menus li .sub_menu_items ul.sub_menu li a:hover svg path {
    fill: #3B82F6 !important;
  }

  #side_bar ul.main-menus li .sub_menu_items ul.sub_menu li a:hover {
    color: #3B82F6;
  }

  #side_bar ul.main-menus li .sub_menu_items ul.sub_menu li.submenu_actv a {
    color: #3B82F6 !important;
  }

  #side_bar ul.main-menus li .sub_menu_items ul.sub_menu li.submenu_actv a svg path {
    fill: #3B82F6 !important;
  }

  #side_bar ul.main-menus li .sub_menu_items ul.sub_menu {
    margin-left: 27px;
    position: relative;
    width: 330px;
  }

  #side_bar ul.main-menus li .sub_menu_items ul.sub_menu li {
    padding: 14px 40px;
    transition: 0.3s ease;
    position: relative;
  }

  #side_bar ul.main-menus li .sub_menu_items ul.sub_menu li::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: 1px;
    width: 36px;
    background: #f1f2f3;
    transform: translate(0, -50%);
    z-index: -1;
  }

  #side_bar ul.main-menus li .sub_menu_items ul.sub_menu li.submenu_actv {
    background: #EFF6FF;
  }

  #side_bar ul.main-menus li .sub_menu_items ul.sub_menu li:hover {
    background: #EFF6FF;
  }

  #side_bar ul.main-menus li .sub_menu_items ul.sub_menu::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background: #f1f2f3;
  }

  #side_bar ul.main-menus li .sub_menu_items ul.sub_menu li a span.submenu_name {
    text-transform: capitalize;
  }

  /* _________________ SIDE BAR CSS ___________________________  */


  /* _________________ RIGHT PANEL _________________ */

  div#right_panel {
    width: 88%;
    /* max-width: 1268px; */
    height: 100%;
    transition: all 0.5s;
    position: fixed;
    top: 0;
    right: 0;
    outline: 1px solid #ECF3F5;
  }

  div#right_panel.show {
    width: 100%;
    padding-left: 0px;
  }

  div#right_panel .search_bar {
    background: #ffffff;

  }

  div#right_panel .search_bar.show {
    left: 0px;
    width: 100%;
    height: 81px;
    align-items: center;
    display: inline-flex;
  }

  div#right_panel .search_bar.show .profile {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 50px;
  }

  div#right_panel .search_bar.show .profile .image {
    margin-left: 16px;
  }

  div#right_panel .search_bar.show .profile .name {}

  div#right_panel .search_bar.show .profile .name h4 {
    width: 101px;
    height: 18px;
    margin: 0px;
    font-size: 14px;
    color: #272727;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    line-height: 18.2px;
    text-align: right;

  }

  div#right_panel .search_bar.show .profile .name p {
    font-size: 14px;
    color: #272727;
    margin: 0px;
    text-decoration: underline;
    color: #00ABFB;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    line-height: 18.2px;
    text-align: right;
  }


  div#right_panel .search_bar.show .header {
    position: relative;
    left: 30px;
  }

  div#right_panel .search_bar.show .header-content {
    display: inline-flex;
    align-items: center;
    justify-content: center;

  }

  div#right_panel .search_bar.show .header .header-content .header-text {
    font-size: 18px;
    color: #272727;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    line-height: 26px;
  }

  div#right_panel .search_bar.show .header .header-content .header-drop {
    margin-left: 60px;
  }

  div#right_panel .search_bar.show .header .header-content .header-drop select {
    width: 315px;
    height: 48px;
    border: 1.5px solid #C6CCD2;
    border-radius: 14px;
    padding-left: 10px;

    font-size: 14px;
    color: #272727;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    line-height: 18px;

  }

  div#right_panel .search_bar.show .header .header-content .header-drop select {}

  /* _________________ RIGHT PANEL _________________ */




  /* _______________________ question-bank ____________________________ */
  .question-bank-table-top {
    padding: 30px 30px;
  }

  .question-bank {
    width: 100%;
    /* padding: 30px 30px; */
  }

  .question-bank .question-bank-report {
    display: inline-flex;
    align-items: center;
    width: 100%;

  }

  .question-bank .question-bank-report .results {
    width: 65%;
    height: 108px;
    gap: 20px;
    display: inline-flex;
  }





  .question-bank .question-bank-report .results .approved {
    max-width: 277px;
    width: 100%;
    background-color: #ffffff;
    height: 108px;
    outline: 0.5px solid #26AB5B;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .question-bank .question-bank-report .results .approved .box {
    width: 219.27px;
    height: 60px;
    display: flex;
    align-items: center;
    gap: 18px;
    padding: 7px;
  }

  .question-bank .question-bank-report .results .approved .box .icons {
    width: 55.8px;
    height: 56px;
    background-color: #E9FCF1;

    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .question-bank .question-bank-report .results .approved .box .score {
    width: 145.47px;
    height: 60px;

  }

  .question-bank .question-bank-report .results .approved .box .score p {
    margin: 0px;
    font-size: 16px;
    color: #212529;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    line-height: 20px;

  }


  .question-bank .question-bank-report .results .approved .box .score span {
    margin: 0px;
    font-size: 28px;
    color: #212529;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    line-height: 32px;

  }


















  .question-bank .question-bank-report .results .Rejected {
    max-width: 277px;
    width: 100%;
    background-color: #ffffff;
    height: 108px;
    outline: 0.5px solid #FF5757;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .question-bank .question-bank-report .results .Rejected .box {
    width: 219.27px;
    height: 60px;
    display: flex;
    align-items: center;
    gap: 18px;
    padding: 7px;
  }

  .question-bank .question-bank-report .results .Rejected .box .icons {
    width: 55.8px;
    height: 56px;
    background-color: #FFF2F1;

    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .question-bank .question-bank-report .results .Rejected .box .score {
    width: 145.47px;
    height: 60px;

  }

  .question-bank .question-bank-report .results .Rejected .box .score p {
    margin: 0px;
    font-size: 16px;
    color: #212529;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    line-height: 20px;

  }


  .question-bank .question-bank-report .results .Rejected .box .score span {
    margin: 0px;
    font-size: 28px;
    color: #212529;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    line-height: 32px;

  }





















  .question-bank .question-bank-report .results .Review {
    max-width: 277px;
    width: 100%;
    background-color: #ffffff;
    height: 108px;
    outline: 0.5px solid #FFCF00;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .question-bank .question-bank-report .results .Review .box {
    width: 219.27px;
    height: 60px;
    display: flex;
    align-items: center;
    gap: 18px;
    padding: 7px;
  }

  .question-bank .question-bank-report .results .Review .box .icons {
    width: 55.8px;
    height: 56px;
    background-color: #FFF9E8;

    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .question-bank .question-bank-report .results .Review .box .score {
    width: 145.47px;
    height: 60px;

  }

  .question-bank .question-bank-report .results .Review .box .score p {
    margin: 0px;
    font-size: 16px;
    color: #212529;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    line-height: 20px;

  }


  .question-bank .question-bank-report .results .Review .box .score span {
    margin: 0px;
    font-size: 28px;
    color: #212529;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    line-height: 32px;

  }

  .add_new_question {
    background-color: #00ABFB;
    max-width: 228px;
    width: 100%;
    height: 56px;
    border: none;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add_new_question span {
    font-size: 16px;
    color: #ffffff;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    line-height: 20px;
    margin-left: 10px
  }

  .add-question {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: 108px;
  }

  .table-content-report {
    width: 100%;
    overflow-y: scroll;
    background-color: #ffffff;
    border-radius: 20px;
    margin-top: 2%;
    height: 380px;
  }

  .table-content-report .top-content-table {
    width: 100%;
    height: 77px;
    display: flex;
    align-items: center;
  }

  .table-content-report .top-content-table .table-title {
    height: 31px;
    gap: 24px;
    display: flex;
    width: 30%;
    margin-left: 20px;


  }

  .table-content-report .top-content-table .table-title span {
    font-size: 16px;
    color: #272727;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    line-height: 20px;
  }

  /* .table-content-report .top-content-table .table-title span:hover {
    border-bottom: 5px solid #00ABFB;
  } */

  /* .table-content-report .top-content-table{
        
      }
      .table-content-report .top-content-table{
        
      } */


  .table-content-report .top-content-table .search-listing {
    width: 50%;
    height: 40px;
    background-color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #eceff1;
  }

  .filter {
    width: 20%;
    height: 24px;
    display: flex;
    justify-content: end;
    margin-right: 20px;

  }

  .filter div {
    display: inline-flex;
    align-items: center;
  }

  .filter div span {
    font-size: 14px;
    color: #272727;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    line-height: 20px;
    margin-left: 8px;
  }

  .table-content-report .sub-head-content-table table {
    border-spacing: 0px !important;
    /* max-width: 1158px; */
    width: 100%;
  }

  .table-content-report .sub-head-content-table table thead {
    background-color: #E9F8FF;
    height: 41px;
    text-align: center;
    font-size: 12px;
    color: #272727;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    line-height: 14px;
  }


  .table-content-report .sub-head-content-table table thead .very-first {
    text-align: left !important;
    padding-left: 26px;
    height: 28px;
    /* width: 147px; */
  }

  .table-content-report .sub-head-content-table table thead .first {
    height: 28px;
    /* width: 110px; */
  }

  .table-content-report .sub-head-content-table table thead .second {
    height: 28px;
    /* width: 86px; */
  }

  .table-content-report .sub-head-content-table table thead .third {
    height: 28px;
    /* width: 105px; */
  }

  .table-content-report .sub-head-content-table table thead .fourth {
    height: 28px;
    /* width: 66px; */
  }

  .table-content-report .sub-head-content-table table thead .fifth {
    height: 28px;
    /* width: 74px; */
  }


  .table-content-report .sub-head-content-table table thead th span {
    margin-right: 4px;
  }


  .general-button {
    width: 90px;
    height: 28px;
    border-radius: 50px;
    background-color: #F4F5F6;
    border: none;
    font-size: 10px;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
  }

  .general-tag {
    display: flex;
    gap: 10px;
    justify-content: center;
    /* margin-left: 26px; */
  }

  .author-tag {
    font-size: 12px;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    font-style: italic;
    /* text-align: center; */
    /* margin-left: 26px; */
  }

  .created-date,
  .lod {
    font-size: 12px;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    /* margin-left: 26px; */
  }

  .status {
    /* margin-left: 26px; */
    /* display: flex; */
    /* justify-content: center; */
    text-align: center;
  }

  .action {
    /* margin-left: 26px; */
    margin-right: 26px;
    position: relative;
  }

  .status span {
    background-color: #FFF9E8;
    color: #FFCF00;
    width: 62px;
    height: 20px;
    border: 0.3px solid #FFCF00;
    border-radius: 4px;
    font-size: 10px;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    padding: 4px 8px 4px 8px;


  }

  /* .active span {
    background-color: #E9FCF1;
    color: #26AB5B;
    width: 62px;
    height: 20px;
    border: 0.3px solid #26AB5B;
    border-radius: 4px;
    font-size: 10px;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    padding: 4px 8px 4px 8px;


  } */

  .activate {
    background-color: #E9FCF1;
    color: #26AB5B;
    width: 62px;
    height: 20px;
    border: 0.3px solid #26AB5B;
    border-radius: 4px;
    font-size: 10px;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    padding: 4px 8px 4px 8px;
    float: right;

  }

  /* .inactive span {
    background-color: #F6F7FB;
    color: #8E9EAB;
    width: 62px;
    height: 20px;
    border: 0.3px solid #8E9EAB;
    border-radius: 4px;
    font-size: 10px;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    padding: 4px 8px 4px 8px;


  } */
  .inactive {
    background-color: #F6F7FB;
    color: #8E9EAB;
    width: 62px;
    height: 20px;
    border: 0.3px solid #8E9EAB;
    border-radius: 4px;
    font-size: 10px;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    padding: 4px 8px 4px 8px;
    float: right;


  }

  .question-id-tag {
    /* width: 420px; */
    height: 54px;
    display: inline-flex;
    margin-left: 13px;
    align-items: center;
  }

  .question-id-tag .id {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-decoration: underline;
    line-height: 16px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    margin-right: 25px;
  }

  .question-id-tag .tag {
    /* width: 349px; */
    text-align: center;
  }

  .question-id-tag .tag p {
    font-size: 12px;
    line-height: 14px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    height: 14px;
    margin-top: 0px;
  }

  .question-id-tag .tag span {
    font-size: 10px;
    line-height: 14px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    height: 14px;
    background-color: #E9F8FF;
    border-radius: 50px;

  }


  .sub-head-content-table table tbody tr td {
    border-bottom: 2px solid #f6f7fb !important;
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 13px;
    padding-right: 13px;
  }



  .create-question {
    padding-left: 3%;
    padding-top: 2%;
  }

  .create-question .create-title {
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    font-family: 'IBM Plex Sans', sans-serif;
    margin-bottom: 1%;
  }

  .create-question .create-question-screen-list span {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #C7CCD1;
  }

  .create-question .create-question-screen-list .active {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #00ABFB;
  }


  .question-ID {
    padding-left: 3%;
    width: 85%;
  }

  .question-ID .title {

    width: 100%;
    height: 68px;
    background-color: #ffffff;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 2%;
    margin-top: 2%;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    box-shadow: 0px 0px 10px rgb(238, 235, 235);
  }


  .question-ID .title span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .question-ID .select-question {
    width: 100%;
    background-color: #ffffff;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    padding-left: 2%;
    padding-top: 1.5%;
    margin-top: 2px;
    padding-bottom: 5%;
    overflow-y: scroll;
    max-height: 400px;
    height: 100%;
  }

  .question-ID .select-question .top-bar .top-bar-title span {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .select-question .list {
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding-top: 3.2%;
    padding-bottom: 3.2%;
  }

  .select-question .list .box {
    flex: 0 0 calc(33.333% - 30px);
    width: 100%;
    height: 140px;
    box-shadow: 0px 0px 10px rgb(224 213 213);
    border-radius: 20px;
    position: relative;
  }

  .select-question .list .active-box {
    flex: 0 0 calc(33.333% - 30px);
    width: 340px;
    height: 140px;
    box-shadow: 0px 0px 6px #00abfb;
    border-radius: 20px;
    position: relative;
  }


  .select-question .list .box .title-list-box,
  .select-question .list .active-box .title-list-box {
    left: 15px;
    position: absolute;
  }

  .select-question .list .box .question-list-box,
  .select-question .list .active-box .question-list-box {
    right: 20px;
    bottom: 0px;
    position: absolute;
  }

  .select-question .list .box .question-list-box p,
  .select-question .list .active-box .question-list-box p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .select-question .list .box .question-list-box p span,
  .select-question .list .active-box .question-list-box p span {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    font-family: 'IBM Plex Sans', sans-serif;
  }


  .select-question .list .box .smallBall,
  .select-question .list .active-box .smallBall {
    right: 45px;
    position: absolute;
  }

  .select-question .list .box .largeBall,
  .select-question .list .active-box .largeBall {
    right: 0px;
    position: absolute;
  }

  .select-question .list .box .largeBall svg,
  .select-question .list .active-box .largeBall svg {
    border-top-right-radius: 20px;
  }

  .saveButton-questionBank {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 26px;
  }

  .saveButton-questionBank .cancel {
    text-decoration: underline;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .select-question .info {
    display: flex;
    flex-wrap: wrap;
    gap: 56px;
    padding-top: 3.2%;
    padding-bottom: 3.2%;
  }

  /* .organisation_info .info{
    display: flex;
    flex-wrap: wrap;
    gap: 56px;
    padding-top: 3.2%;
    padding-bottom: 3.2%;
  }
  .organisation_info .info .format-choice {
    display: flex;
    margin-top: 16px;
    gap: 26px;
  }
  .organisation_info .info .format-choice label {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    font-family: 'IBM Plex Sans', sans-serif;
  } */
  .oragnisation_status {
    display: flex;
  }

  .status_Organisation {
    display: inline-block;
    float: right;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .select-question .info .format {
    max-height: 56px;
    max-width: 305px;
    width: 100%;
    height: 100%;
  }

  .select-question .info .format_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .select-question .info .format-choice {
    display: flex;
    margin-top: 16px;
    gap: 26px;
  }

  .select-question .info .format-choice div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .select-question .info .format-choice label {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .select-question .info .format-choice input {
    margin: 0px;
    margin-right: 6px;
  }

  .select-question .primary-tag {
    margin-bottom: 30px;
  }

  .select-question .primary-tag .tag .tag_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .select-question .primary-tag .tag .tag_desc {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #212529;
    margin-top: 10px;
  }

  .select-question .primary-tag .tag_search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 0px solid #ffffff;
    box-shadow: 0px 0px 10px rgb(226, 218, 218);
    max-width: 574px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .select-question .primary-tag .tag_search .tag_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80px;
    width: 100%;
  }

  .select-question .primary-tag .tag_search .tag_icon span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-left: 10px;
  }

  .select-question .primary-tag .tag_search .input-search {
    border-left: 1px solid rgb(235, 225, 225);
    height: 100%;
    display: flex;

    width: 100%;
    position: relative;
  }

  .select-question .primary-tag .tag_search .input-search input,
  .select-question .primary-tag .tag_search .input-search input:focus-visible {
    border: none;
    outline: none;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding-left: 20px;
  }

  .select-question .primary-tag .tag_search .cancel_button {
    float: right;
  }

  .select-question .primary-tag .tag_search .cancel_button button {
    border: none;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Remove the clear (cross) icon from the search input */
  #searchInput::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    display: none;
  }

  /* Remove the clear (cross) icon from the search input for Mozilla Firefox */
  #searchInput::-webkit-search-clear-button {
    -webkit-appearance: none;
    appearance: none;
    display: none;
  }




  .select-question .Secondary-tag {
    margin-bottom: 30px;
  }

  .select-question .Secondary-tag .tag .tag_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .select-question .Secondary-tag .tag .tag_desc {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #212529;
    margin-top: 10px;
  }

  .select-question .Secondary-tag .tag_search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 0px solid #ffffff;
    box-shadow: 0px 0px 10px rgb(226, 218, 218);
    max-width: 574px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .select-question .Secondary-tag .tag_search .tag_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80px;
    width: 100%;
  }

  .select-question .Secondary-tag .tag_search .tag_icon span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-left: 10px;
  }

  .select-question .Secondary-tag .tag_search .input-search {
    border-left: 1px solid rgb(235, 225, 225);
    height: 100%;
    display: flex;

    width: 100%;
    position: relative;
  }

  .select-question .Secondary-tag .tag_search .input-search input,
  .select-question .Secondary-tag .tag_search .input-search input:focus-visible {
    border: none;
    outline: none;
    width: 100%;
    padding-left: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .select-question .Secondary-tag .tag_search .cancel_button {
    float: right;
  }

  .select-question .Secondary-tag .tag_search .cancel_button button {
    border: none;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }













  .select-question .free-tag {
    margin-bottom: 30px;
  }

  .select-question .free-tag .tag .tag_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .select-question .free-tag .tag .tag_desc {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #212529;
    margin-top: 10px;
  }

  .select-question .free-tag .tag_search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 0px solid #ffffff;
    box-shadow: 0px 0px 10px rgb(226, 218, 218);
    max-width: 574px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .select-question .free-tag .tag_search .tag_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80px;
    width: 100%;
  }

  .select-question .free-tag .tag_search .tag_icon span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-left: 10px;
  }

  .select-question .free-tag .tag_search .input-search {
    border-left: 1px solid rgb(235, 225, 225);
    height: 100%;
    display: flex;
    padding-left: 20px;
    width: 100%;
    position: relative;
  }

  .select-question .free-tag .tag_search .input-search input,
  .select-question .free-tag .tag_search .input-search input:focus-visible {
    border: none;
    outline: none;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .select-question .free-tag .tag_search .cancel_button {
    float: right;
  }

  .select-question .free-tag .tag_search .cancel_button button {
    border: none;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }



  .list-input-primary {
    position: absolute;
    top: 55px;
    border: 1px solid white;
    background-color: white;
    box-shadow: 0px 5px 10px rgb(231, 226, 226);
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    z-index: 50;
  }

  .list-input-primary div {
    padding: 8px 5px;
  }

  .list-input-primary div:hover span {
    background-color: #00ABFB;
    color: white;
  }

  .list-input-primary div span {
    border-radius: 50px;
    background-color: #F4F5F6;
    padding: 5px 22px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
  }

  .input-search span {

    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding-left: 20px;
    align-items: center;
    display: inline-flex;
  }

  .Secondary-tag .tag .tag_search .input-search .selected-tags {
    border-radius: 50px;
    background-color: #F4F5F6;
    padding: 2px 22px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
  }


  .free-tag .tag .tag_search .input-search .selected-tags {
    border-radius: 50px;
    background-color: #F4F5F6;
    padding: 2px 22px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
  }
  /* * yaha se nahi hai*/
  .select-question .question-type-box {
    border: 1px solid white;
    box-shadow: 0px 0px 10px #ece4e4;
    width: 90%;
    height: 100%;
    padding: 20px;
    border-radius: 10px;

  }

  .select-question .question-type-box .question-type-title {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
  }

  .select-question .question-type-box .question-type-title svg {
    float: right;
  }

  .select-question .question-type-box .category {
    display: flex;
    gap: 10px;
    margin-top: 40px;
    height: 90%;
  }

  .select-question .question-type-box .category .sub-category {
    padding-right: 0px;
    border-right: 1px solid gray;
    width: 18%;

  }

  .select-question .question-type-box .category .sub-category div {
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'IBM Plex Sans', sans-serif;
    margin-bottom: 15px;
  }

  .select-question .question-type-box .category .name-category {
    width: 82%;
    position: relative;
    padding-left: 20px;
  }

  .select-question .question-type-box .category .name-category .selection-box {
    display: flex;
    gap: 20px;
    margin-bottom: 5%;
  }

  .select-question .question-type-box .category .name-category .select-type p {
    /* border: 2px solid black; */
    margin-top: 0px;
    line-height: 16px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #212529;
  }


  .select-question .question-type-box .category .name-category .select-type .select-box-type {
    border: 1px solid #C7CCD1;
    border: 1px solid #C7CCD1;
    padding: 13px 20px;

  }


  .select-question .question-type-box .category .name-category .select-type .select-box-type p {
    line-height: 14px;
    font-size: 10px;
    font-weight: 400;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #212529;
    margin-top: 0px;
  }

  .select-box-type div {
    display: flex;
    margin-bottom: 5px;
    /* justify-content: center; */
    align-items: center;

  }

  .select-box-type div label {
    line-height: 14px;
    font-size: 10px;
    font-weight: 400;
    font-family: 'IBM Plex Sans', sans-serif;
    margin-left: 5px;
  }

  .select-box-type div input {
    margin: 3px;
  }

  .saveButton-questionCreation {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 26px;
    position: relative;
    z-index: 50;

  }

  .saveButton-questionCreation .cancel {
    text-decoration: underline;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .select-question .question-creating {
    border: 1px solid white;
    box-shadow: 0px 0px 10px #ece4e4;
    width: 60%;
    height: 100%;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #00ABFB;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
    padding-right: 10px;
  }

  .select-question .question-creating .question-creating-title {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
  }

  .select-question .question-creating .question-creating-title svg {
    float: right;
  }

  .select-question .explanation_text {
    width: 60%;
    margin-top: 40px;
  }

  .select-question .explanation_text .tox-tinymce {
    height: 200px !important
  }

  .select-question .explanation_text p {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-left: 5px;
  }



  .select-question .compose_text {
    width: 60%;
    margin-top: 40px;
  }

  .select-question .compose_text .tox-tinymce {
    height: 200px !important
  }

  .select-question .compose_text p {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-left: 5px;
  }


  .select-question .Answer_text {
    width: 70%;
    margin-top: 40px;
  }

  .select-question .Answer_text .tox-tinymce {
    height: 200px !important
  }

  .select-question .Answer_text p {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-left: 5px;
    max-width: 85%;
  }

  .select-question .Answer_text .select {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .select-question .enter-answer {
    margin-top: 40px;
    display: flex;
    align-items: center;
    width: 59.9%;
  }

  .select-question .enter-answer .title-detail {
    width: 50%;
  }

  .select-question .enter-answer .random-text {
    text-align: right;
    width: 50%;
  }

  .select-question .enter-answer .title-enter-answer {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 5px;

  }

  .select-question .enter-answer .title-enter-desc {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .tinymce-text {
    margin-bottom: 50px;
  }

  .back-button {
    border: none;
    padding: 15px 15px;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }

  .Add-answer {

    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .Add-answer span {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    margin-left: 8px;
    color: #00ABFB;
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  .Answer_text .editor-box {
    display: flex;
    align-items: center;
    gap: 20px;
    max-width: 100%;
  }

  .Answer_text .editor-box .close-answer {
    display: flex;
    align-items: center;
    background-color: #F6F7FB;
    border-radius: 10px;
    padding: 8px 12px;
  }

  .Answer_text .editor-box .editor {
    width: 85%;
  }

  .Answer_text .editor-box .close-answer span {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin-left: 8px;

  }

  .explanation_text .explanation-video {
    border: 2px solid #ECF3F5;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 10px 10px;
  }

  .explanation_text .explanation-video input,
  .explanation_text .explanation-video input:focus-visible {
    border: none;
    outline: none;
    width: 100%;
    padding: 10px 10px;
  }

  .select-question .Question_Direction {
    width: 60%;
    margin-top: 40px;
  }

  .select-question .Question_Direction .tox-tinymce {
    height: 200px !important
  }

  .select-question .Question_Direction p {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-left: 5px;
  }

  .select-question .send-for-review {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

  }

  .send-for-review .top {
    text-align: center;
    /* border-bottom: 2px solid black; */
    /* padding-bottom: 5%; */
  }

  .send-for-review .create .line-div {
    padding: 4% 10%;
  }

  .send-for-review .create .line-div .line {
    border-top: 2px solid #ECF3F5;
  }

  .send-for-review .create {
    text-align: center;
    /* margin-top: 6%; */

  }

  .send-for-review .create h4 {
    margin: 0px;
  }

  .select-question .correctAnswer {
    width: 60%;
    margin-top: 40px;
  }

  .select-question .correctAnswer .tox-tinymce {
    height: 200px !important
  }

  .select-question .correctAnswer p {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-left: 5px;
  }

  .select-question .correctAnswer textarea {
    border: 2px solid #ECF3F5;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    width: 97%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }





  .select-question .answertype {
    width: 60%;
    margin-top: 40px;
  }

  .select-question .answertype p {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-left: 5px;
  }

  .select-question .answertype select {
    border: 2px solid #ECF3F5;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    width: 30%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }



  .select-question .correctAnswer_range {
    width: 60%;
    margin-top: 40px;
  }

  .select-question .correctAnswer_range p {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .select-question .correctAnswer_range .range-calculate {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 40px;
  }

  .select-question .correctAnswer_range .range-calculate .range {
    display: flex;
    align-items: center;
    width: 65%;
    gap: 40px;
  }

  .select-question .correctAnswer_range .range-calculate .rangetext {
    width: 34%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
  }

  .select-question .correctAnswer_range .range-calculate .rangetext input {
    width: 94%;
    border: 2px solid #ECF3F5;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    margin-top: 2%;
  }

  .select-question .correctAnswer_range .range-calculate .range .from {
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
  }

  .select-question .correctAnswer_range .range-calculate .range .from input {
    width: 100%;
    border: 2px solid #ECF3F5;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    margin-top: 2%;
  }

  .select-question .correctAnswer_range .range-calculate .range .to {
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
  }

  .select-question .correctAnswer_range .range-calculate .range .to input {
    width: 100%;
    border: 2px solid #ECF3F5;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    margin-top: 2%;
  }





  .select-question .correctAnswer_format {
    width: 60%;
    margin-top: 40px;
  }

  .select-question .correctAnswer_format p {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .select-question .correctAnswer_format .range-calculate {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 40px;
  }

  .select-question .correctAnswer_format .range-calculate .range {
    display: flex;
    align-items: center;
    width: 85%;
    gap: 40px;
  }


  .select-question .correctAnswer_format .range-calculate .range .from {
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
  }

  .select-question .correctAnswer_format .range-calculate .range .from input {
    width: 100%;
    border: 2px solid #ECF3F5;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    margin-top: 2%;
  }

  .select-question .correctAnswer_format .range-calculate .range .to {
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
  }

  .select-question .correctAnswer_format .range-calculate .range .to input {
    width: 100%;
    border: 2px solid #ECF3F5;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    margin-top: 2%;
  }



  .select-question .explanation_line {
    width: 60%;
    margin-top: 40px;
  }

  .select-question .explanation_line .line {
    border: 2px solid #ECF3F5;
  }

  .multiquestion {
    display: flex;
    justify-content: start;
    gap: 10px;
  }

  .questions-remove {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 63.5%;
    margin: 20px 0px;
  }

  .questions-remove .titles,
  .remove {
    width: 50%;
  }

  .remove-question {
    display: flex;
    align-items: center;
    background-color: #F6F7FB;
    border-radius: 10px;
    padding: 8px 12px;
    float: right;

  }



 .select-question .passage_text {
    width: 60%;
    margin-bottom: 40px;
  }

  .select-question .passage_text .tox-tinymce {
    height: 200px !important
  }

  .select-question .passage_text p {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-left: 5px;
  }





  .search-listing {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border: 1px solid #d9d1d1; */
    box-shadow: 0px 0px 5px rgb(203, 201, 201);
    /* max-width: 574px; */
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .search-listing .tag_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100px;
    width: 100%;
  }

  .search-listing .tag_icon span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-left: 5px;
  }

  .search-listing .input-search {
    border-left: 1px solid rgb(235, 225, 225);
    height: 100%;
    display: flex;
    width: 100%;
    position: relative;
  }

  .search-listing .input-search input,
  .search-listing .input-search input:focus-visible {
    border: none;
    outline: none;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding-left: 20px;
  }

  .search-listing .cancel_button {
    float: right;
  }

  .search-listing .cancel_button button {
    border: none;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .search-listing .tag_icons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
  }

  .search-listing .input-searchs {
    height: 100%;
    display: flex;
    width: 100%;
    position: relative;
  }

  .search-listing .input-searchs input,
  .search-listing .input-searchs input:focus-visible {
    border: none;
    outline: none;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding-left: 0px;
  }


  .user_management_table_body_content {
    font-size: 12px;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .user_management_table_body_content .in-review {
    position: relative;
    display: inline-block;
    font-size: 10px;
    height: 4vh;

    font-size: small;
    cursor: pointer;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
    background: #fff9e8;
    color: #ffcf00;
    border: 0.3px solid #ffcf00;
    font-size: x-small;
  }

  .user_management_search_listing {
    margin: 10px 30px;
  }

  .user_management_filter {
    justify-content: start;
  }

  .header_text_title {
    font-size: 18px;
    color: #272727;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    line-height: 26px;
  }

  .profile_title_div {
    font-size: 18px;
    color: #272727;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    line-height: 26px;
    margin-left: 30px;
  }


  .user_profile .tags .subject p {
    font-size: 14px;
    color: #272727;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    line-height: 18px;
  }

  .user_profile .tags .subject {
    width: 30%;
  }

  .user_profile .tags .subject input {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 5px rgb(203, 201, 201);
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    padding: 20px;
    font-size: 12px;
  }

  .user_profile .tags {
    display: flex;
    gap: 20px;
  }

  .user_profile {
    padding: 0px 30px;
  }

  .profile_status {
    padding: 10px 30px;
  }

  .profile_status .info {
    padding: 20px 0px;
  }

  .profile_status .title {
    font-size: 14px;
    color: #272727;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    line-height: 18px;
  }

  .question_bank_table {
    border: 1px solid #ECF3F5;
    border-radius: 10px;
    margin-top: 10px;
  }

  .thead_question_bank {
    display: flex;
    width: 100%;
    font-size: 18px;
    color: #272727;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    line-height: 20px;
  }

  .thead_question_bank div {
    width: 100%;
    padding: 20px;
    text-align: center;


  }

  .thead_question_bank .first {
    border-top-left-radius: 10px;
    background-color: #F6F7FB;
    border-bottom: 1px solid #ECF3F5;
    text-align: left;
  }

  .thead_question_bank .last {
    border-top-right-radius: 10px;
    background-color: #F6F7FB;
    border-bottom: 1px solid #ECF3F5;
  }

  .thead_question_bank .second {
    background-color: #F6F7FB;
    border: 1px solid #ECF3F5;
  }

  .thead_question_bank .third {
    background-color: #F6F7FB;
    border: 1px solid #ECF3F5;
  }

  .thead_question_bank .fouth {
    background-color: #F6F7FB;
    border: 1px solid #ECF3F5;
  }


  .tbody_question_bank .first {
    text-align: left;
  }

  .tbody_question_bank .last {
    border-left: 1px solid #ECF3F5;

  }

  .tbody_question_bank .second {
    border-left: 1px solid #ECF3F5;
  }

  .tbody_question_bank .third {
    border-left: 1px solid #ECF3F5;
  }

  .tbody_question_bank .fouth {
    border-left: 1px solid #ECF3F5;
  }



  .tbody_question_bank {
    display: flex;
    width: 100%;
    font-size: 18px;
    color: #272727;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    line-height: 20px;
    border-bottom: 1px solid #ECF3F5;


  }

  .tbody_question_bank div {
    width: 100%;
    padding: 20px;
    text-align: center;

  }


  .profile_detail_div .status {
    float: right;
    background-color: #E9FCF1;
    border: 1px solid #26AB5B;
    color: #26AB5B;
    font-size: 16px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    line-height: 20px;
    border-radius: 10px;
    padding: 5px 20px;
  }

  .profile_detail_div {
    font-size: 18px;
    color: #272727;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    line-height: 26px;
    padding: 20px 40px 10px 30px;
  }

  .profile_detail_info {
    padding: 20px;
  }

  .title_detail {
    font-size: 14px;
    font-weight: 600;
    font-family: 'IBM Plex Sans', sans-serif;
    line-height: 20px;
  }

  .description_detail {
    font-size: 12px;
    font-weight: 400;
    font-family: 'IBM Plex Sans', sans-serif;
    line-height: 20px;
  }

  .profile_detail_info .second,
  .profile_detail_info .third,
  .profile_detail_info .fouth,
  .profile_detail_info .last {
    text-align: left;
  }

  .top-section {
    display: block;
  }

  .top-section .left {
    display: inline-flex;
    align-items: center;
    text-decoration: underline;
  }

  .top-section .right {
    display: inline-flex;
    float: right;
    align-items: center;
    color: #00ABFB;
    font-size: 16px;
    font-weight: 400;
    font-family: 'IBM Plex Sans', sans-serif;
    line-height: 20px;
  }

  .Master_div {
    display: flex;
    justify-content: center;
  }

  .master_category {
    width: 70%;
  }

  .input-search-master {
    height: 100%;
    display: flex;

    width: 100%;
    position: relative;


  }

  .input-search-master input {
    border: none;
    outline: none;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding-left: 20px;
    border-radius: 10px;
  }

  .search_icon {
    padding-left: 20px;
  }

  .category_table thead .first {

    text-align: left;
    padding-left: 5%;
    width: 25%;
  }

  .category_table thead .second {

    text-align: left;
    padding-left: 5%;
    width: 55%;
  }

  .category_table table tbody tr td {
    text-align: left;
    padding-left: 5%;

    font-size: 12px;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
  }

  .remove-master-one {
    display: flex;
    align-items: center;
    background-color: #F6F7FB;
    border-radius: 10px;
    padding: 8px 12px;
    justify-content: center;
  }











  .Identifier_table thead .first {

    text-align: left;
    padding-left: 5%;
    width: 25%;
  }

  .Identifier_table thead .second {

    text-align: left;
    padding-left: 5%;
    width: 25%;
  }

  .Identifier_table thead .third {

    text-align: left;
    padding-left: 5%;
    width: 25%;
  }

  .Identifier_table table tbody tr td {
    text-align: left;
    padding-left: 5%;

    font-size: 12px;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
  }

  .test_type {
    border-radius: 50px;
    background-color: #F4F5F6;
    border: none;
    font-size: 10px;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    padding: 5px 10px;
  }




  .LOD thead .first {

    text-align: left;
    padding-left: 5%;
    width: 80%;
  }



  .LOD thead .fifth {

    text-align: left;
    padding-left: 5%;
    width: 20%;
  }

  .LOD table tbody tr td {
    text-align: left;
    padding-left: 5%;

    font-size: 12px;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
  }


  .master_tag_search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 0px solid #ffffff;
    box-shadow: 0px 0px 10px rgb(226, 218, 218);
    max-width: 574px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .master_tag_search .tag_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80px;
    width: 100%;
  }

  .master_tag_search .tag_icon span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-left: 10px;
  }

  .master_tag_search .input-search {
    border-left: 1px solid rgb(235, 225, 225);
    height: 100%;
    display: flex;
    padding-left: 20px;
    width: 100%;
    position: relative;
  }

  .master_tag_search .input-search input,
  .master_tag_search .input-search input:focus-visible {
    border: none;
    outline: none;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .master_tag_search .cancel_button {
    float: right;
  }

  .master_tag_search .cancel_button button {
    border: none;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }



  .SubjectPipeline_table thead .first {

    text-align: left;
    padding-left: 5%;
    width: 10%;
  }

  .SubjectPipeline_table thead .second {

    text-align: left;
    padding-left: 5%;
    width: 10%;
  }

  .SubjectPipeline_table thead .fourth {

    text-align: center;
    padding-left: 5%;
    width: 40%;
  }

  .SubjectPipeline_table table tbody tr td {
    text-align: left;
    padding-left: 5%;

    font-size: 12px;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
  }

  .master_subjectPipeline {
    width: 95%;
  }

  .slugs_Tags {
    width: 90px;
    height: 28px;
    border-radius: 50px;
    background-color: #E9F8FF;
    border: none;
    /* font-size: 10px; */
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    padding: 5px 20px;
  }


  .slugs_Tag {
    width: 90px;
    height: 28px;
    border-radius: 50px;
    background-color: #E9F8FF;
    border: none;
    font-size: 10px;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    padding: 5px 20px;
  }

  .dropdown_css {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 5px rgb(203, 201, 201);
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    padding: 0px 20px;
    font-size: 12px;
  }

  .roleBox {
    color: black;
    margin: 5px 10px;
    padding: 8px 12px 8px 12px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    line-height: 20px;
    background: #F6F7FB;
    border: none;
    border-radius: 10px;
  }

  .roleBox svg {
    margin-top: 5px;
    margin-left: 10px;
  }

  .user_management_table_body_content span {
    background-color:
      #ECF3F5;
    /* border: 1px solid black; */
    border-radius: 20px;
    padding: 4px;
    margin-left: 10px;
  }


  .Submenu_sidebar {
    padding-left: 31%;
    margin-top: -7px;
  }


  .submenu_span {
    font-size: 12px !important;
    margin-bottom: 8px;
    font-weight: 400 !important;
  }






















  /* __________________- ATOMS  _____________________  */
  .margin-top-20 {
    margin-top: 20px;
  }

  .shadow {
    box-shadow: 0px 0px 5px rgb(198, 194, 194);
    border-radius: 10px;
  }

  .blue-button {
    background-color: #00ABFB;
    color: white;
    width: 197px;
    height: 56px;
    border: none;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    font-family: 'IBM Plex Sans', sans-serif;
    cursor: pointer;
  }

  .blue-button-disable {
    background-color: #00abfb88;
    color: white;
    width: 197px;
    height: 56px;
    border: none;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    font-family: 'IBM Plex Sans', sans-serif;
    cursor: not-allowed;
  }

  .multiquestionbutton {
    background-color: #00ABFB;
    color: white;
    width: 185px;
    height: 56px;
    border: none;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    font-family: 'IBM Plex Sans', sans-serif;
    box-shadow: 0px 5px 10px #e8dcdc;
  }

  .multiquestionbuttonBlue {
    background-color: #ffffff;
    color: rgb(9, 7, 7);
    width: 185px;
    height: 56px;
    border: none;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    font-family: 'IBM Plex Sans', sans-serif;
    box-shadow: 0px 5px 10px #e8dcdc;
  }

  .red-mendantory {
    color: red;
  }

  .checked-full {
    background-color: #E9F8FF;
  }

  .underline-bold {
    text-decoration: underline;
    text-underline-offset: 5px;
    font-weight: 900 !important;
  }

  .mediumModal {
    position: fixed;
    height: 100vh;
    background: #000000ba;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 60;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mediumModal .center-div {
    width: 45%;
    background-color: white;
    height: 380px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .mediumModal .center-div h3 {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 0px 120px;
    margin-bottom: 15px;
  }

  .mediumModal .center-div p {
    text-align: center;
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 0px 80px;
    margin-top: 0px;
  }

  .saveButton-questionCreation-Modal {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 26px;
    bottom: 20px;
    z-index: 50;
    margin-top: 20px;

  }

  .saveButton-questionCreation-Modal .cancel {
    text-decoration: underline;

    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .underline-active {
    border-bottom: 5px solid #00ABFB;
  }




  .filterModal {
    position: fixed;
    height: 100vh;
    background: #000000ba;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 60;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filterModal .center-div {
    width: 55%;
    background-color: white;
    height: 85%;
    border-radius: 30px;
  }

  .filterModal .center-div .header-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgb(241, 236, 236);
    -webkit-box-shadow: 0 8px 6px -6px rgb(230, 216, 216);
    -moz-box-shadow: 0 8px 6px -6px rgb(223, 203, 203);
    box-shadow: 0 8px 6px -6px rgb(220, 211, 211);
  }

  .filterModal .center-div .header-top .title {
    padding: 2.2% 3%;
    width: 50%;
  }

  .filterModal .center-div .header-top .title div {
    display: flex;
    align-items: center;
  }

  .filterModal .center-div .header-top .title div span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .filterModal .center-div .header-top .close {
    width: 50%;
    padding-right: 0.5%;

  }

  .filterModal .center-div .header-top .close button {
    float: right;
    margin-right: 5%;
    background-color: white;
    border: none;

  }

  .filterModal .center-div .bottom-fields {
    padding: 3%;
    overflow-y: scroll;
    height: 75%;

  }

  .filterModal .center-div .bottom-fields .tags {
    width: 65%;
  }


  .filterModal .center-div .bottom-fields .drop {
    width: 100%;
    display: flex;
    gap: 20px;
  }

  .filterModal .center-div .bottom-fields .drop .drop-item {
    width: 28%;
  }

  .filterModal .center-div .bottom-fields .drop .tags {
    width: 65%;
  }

  .filterModal .center-div .bottom-fields .tags .subject {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    margin-left: 5px;
  }

  .filterModal .center-div .bottom-fields .drop .drop-item .subject {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    margin-left: 5px;
  }


  .header-drop-filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 5px rgb(203, 201, 201);
    outline: none;
    border: none;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }


  .bottom-fields .blue-button {
    background-color: #00ABFB;
    color: white;
    width: 35%;
    height: 56px;
    border: none;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    font-family: 'IBM Plex Sans', sans-serif;
    margin-top: 4%;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 20px;
    margin-top: 20px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-family: 'IBM Plex Sans', sans-serif;

  }

  .pagination .number {
    display: flex;
    text-transform: capitalize;
    margin-right: 10px;
    align-items: center;
  }


  .pagination .change {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .pagination .line {
    display: flex;
    align-items: center;
  }

  .pagination .change button {
    border: none;
    border-radius: 10px;
    align-items: center;
    display: flex;
    padding: 10px;
  }

  .pagination .change .current {
    margin: 10px;
  }

  .currentpage {
    border: none;
    border-radius: 10px;
    align-items: center;
    display: flex;
    padding: 10px;
  }

  .pointer {
    cursor: pointer;
  }

  .question_identifier {
    width: 97%;
    height: 110px;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 10px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #0f1010;
  }

  .action_button {
    box-shadow: 0px 0px 10px #ddd4d4;
    position: absolute;
    left: -105px;
    top: 28px;
    width: 90px;
    padding: 7px 15px;
    border-radius: 10px;
    z-index: 60;
  }

  .action_buttons {
    box-shadow: 0px 0px 10px #ddd4d4;
    position: absolute;
    left: 10px;
    top: -15px;
    width: 90px;
    padding: 7px 15px;
    border-radius: 10px;
    z-index: 60;
  }


  .action_button .edit {
    padding: 5px 0px;
  }


  .action_button .duplicate {
    padding: 5px 0px;
  }








  .filterModal .center-div .top-title {
    width: 100%;
    /* display: flex;
    align-items: center;
    justify-content: center; */

    /* -webkit-box-shadow: 0 8px 6px -6px rgb(230, 216, 216);
    -moz-box-shadow: 0 8px 6px -6px rgb(223, 203, 203);
    box-shadow: 0 8px 6px -6px rgb(220, 211, 211); */
  }

  .filterModal .center-div .top-title .title {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .filterModal .center-div .top-title .title div {
    display: flex;
    align-items: center;
  }

  .filterModal .center-div .top-title .title div span {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .filterModal .center-div .top-title .close {
    width: 100%;


  }

  .filterModal .center-div .top-title .close button {
    float: right;
    margin-right: 2%;
    margin-top: 2%;
    background-color: white;
    border: none;

  }




  .master_modal .tags .subject p {
    font-size: 14px;
    color: #272727;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    line-height: 18px;
  }

  .master_modal .tags .subject {
    width: 100%;
  }

  .master_modal .tags .subject input {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 5px rgb(203, 201, 201);
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    padding: 20px;
    font-size: 12px;
  }

  .master_modal .tags {
    display: flex;
    gap: 20px;
  }

  .master_modal {
    padding: 0px 50px;
    margin-top: 20px;
  }

  .Master_div .filterModal .center-div {
    width: 35%;
    background-color: white;
    height: 85%;
    border-radius: 30px;
  }

  .description_input {
    height: 100px !important;
    font-family: 'IBM Plex Sans', sans-serif;
    box-shadow: 0px 0px 5px rgb(203, 201, 201);
    width: 90.5%;
    border-radius: 10px;
    border: none;
    padding: 20px;
    font-size: 12px;
  }

  .title-delete {
    font-size: 24px;
    color: #272727;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    padding: 10%;
  }


  .center {
    justify-content: center !important;
    width: 100%;
  }

  .delete {
    height: 40% !important;
  }

  .organisation_table {
    width: 70%;
  }

  .organisation_detail_head {
    height: auto !important;
  }










  .questionpppp {
    display: flex;

  }






















  /* ______________________ DRAG  ______________________ */
  .container {
    margin-top: 1rem;
    display: flex;
    width: 35%;
    gap: 10px;
  }

  .draggable {
    padding: 1rem;
    cursor: move;
    width: 50%;
  }

  .draggable.dragging {
    opacity: .5;
  }





  /* Background Styles Only */

  @import url('https://fonts.googleapis.com/css?family=Raleway');

  .side-links {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .side-link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-bottom: 10px;
    color: white;
    width: 180px;
    padding: 10px 0;
    border-radius: 10px;
  }

  .side-link-youtube {
    background-color: red;
  }

  .side-link-twitter {
    background-color: #1DA1F2;
  }

  .side-link-github {
    background-color: #6e5494;
  }

  .side-link-text {
    margin-left: 10px;
    font-size: 18px;
  }

  .side-link-icon {
    color: white;
    font-size: 30px;
  }


  .filterModal .center-div-large {
    width: 65%;
    background-color: white;
    height: 85%;
    border-radius: 30px;
  }


  .filterModal .center-div-large .top-title {
    width: 100%;
    /* display: flex;
    align-items: center;
    justify-content: center; */

    /* -webkit-box-shadow: 0 8px 6px -6px rgb(230, 216, 216);
    -moz-box-shadow: 0 8px 6px -6px rgb(223, 203, 203);
    box-shadow: 0 8px 6px -6px rgb(220, 211, 211); */
  }

  .filterModal .center-div-large .top-title .title {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .filterModal .center-div-large .top-title .title div {
    display: flex;
    align-items: center;
  }

  .filterModal .center-div-large .top-title .title div span {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .filterModal .center-div-large .top-title .close {
    width: 100%;


  }

  .filterModal .center-div-large .top-title .close button {
    float: right;
    margin-right: 2%;
    margin-top: 2%;
    background-color: white;
    border: none;

  }

  .identifier_master_modal {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .identifier_master_modal .master_modal {
    width: 50%;
  }

  .identifier_save {
    float: right;
    padding: 5%;
  }



  .todo-comment-button {
    padding: 20px;
    padding-left: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .todo-comment-button .comm-bt {
    border-radius: 50px;
    width: 48px;
    height: 48px;
    border: 1px solid #ffffff;
    box-shadow: 0px 8px 40px 0px #7090b033;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .todo-comment-button .comm-bt:focus {
    border: 1px solid #00ABFB;
  }







































  .auto-box {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    overflow: hidden;
  }

  .auto-box p {
    margin: 0;
  }

  .content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* Adjust the number of lines based on your design */
    -webkit-box-orient: vertical;
  }















  /* IRT  */


  .irt-params {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    font-family: "IBM Plex Sans", sans-serif;
  }

  .irt-params .param-container {
    background-color: #fff;
    width: 95%;
    height: 85vh;
    margin-top: 20px;
    border-radius: 18px 18px 0px 0px;
    display: flex;
    box-shadow: 0px 8px 24px 0px #7090b026;
  }

  .student-report-form {
    width: 100%;
  }

  .irt-params .param-container .student-report-form .user-report-header {
    background-color: #fff;
    height: 84px;
    border-radius: 18px 18px 0px 0px;
    display: flex;
    padding: 5px;
    align-items: center;
  }

  .irt-params .param-container .student-report-form .user-report-header .header-content {
    display: flex;
    justify-content: space-around;
    margin-left: 2em;
    gap: 20px;
  }

  .irt-params .param-container .student-report-form .user-report-header .header-content .irt-title {
    font-weight: 500;
  }

  .irt-params .param-container .student-report-form .user-report-header .header-content .view-report {
    color: #00abfb;
    font-weight: 500;
    text-decoration: underline;
  }

  .irt-params .param-container .student-report-form .user-report-header .user-right-options {
    display: flex;
    align-items: center;
    margin-left: 40em;
    gap: 5px;
  }

  .irt-params .param-container .student-report-form .user-report-header .user-right-options .student-report-filter {
    cursor: pointer;
  }

  .irt-params .param-container .student-report-form .user-report-header .user-right-options .download-report-bt {
    background: #00abfb;
    border: 1px solid #00abfb;
    height: 56px;
    width: 212px;
    box-shadow: 0px 16px 40px 0px #7090b033;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #fff;
    border-radius: 10px;
    margin-left: 2em;
    gap: 10px;
    cursor: pointer;
  }

  .irt-params .param-container .student-report-form .user-report-table {
    height: 100%;
    width: 100%;
    display: block;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  .irt-params .param-container .student-report-form .user-report-table table {
    border-collapse: collapse;
    width: 100%;
    font-family: "IBM Plex Sans", sans-serif;
  }

  .irt-params .param-container .student-report-form .user-report-table tr {
    border-bottom: 1px solid #ecf3f5;
  }

  .irt-params .param-container .student-report-form .user-report-table th {
    padding: 10px;
    background-color: #e9f8ff;
    font-weight: 600;
    font-size: 12px;
    width: 14.2855%;
  }

  .irt-params .param-container .student-report-form .user-report-table td {
    padding: 30px;
    font-size: 12px;
    width: 14.28%;
    text-align: center;
  }








  /* comment  */


  /***** COMMENTS SECTION FROM HERE **********/
  .question-bank-table-top .comments-bar {
    flex: 1;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 220px;
    gap: 2em;
  }

  .question-bank-table-top .comments-bar .add-user-comment {
    height: 60vh;
    background-color: rosybrown;
    width: 220px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 8px 24px 0px #7090b026;
  }

  .question-bank-table-top .comments-bar .headline {
    display: flex;
    align-items: center;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: small;
    padding: 10px;
    gap: 5px;
  }

  .question-bank-table-top .comments-bar .user-comment-activity {
    background-color: #fff;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    height: 80vh;
    width: 220px;
    font-family: "IBM Plex Sans", sans-serif;
    box-shadow: 0px 8px 24px 0px #7090b026;
  }

  .question-bank-table-top .comments-bar .user-comment-activity .comments-area {
    display: flex;
    margin-top: 5px;
    font-size: 13px;
    font-weight: 400;
    height: 60vh;
    overflow-y: scroll;
    padding: 5px;
  }

  .question-bank-table-top .comments-bar .user-comment-activity .comments-area::-webkit-scrollbar {
    width: 0px;
  }

  .question-bank-table-top .comments-bar .user-comment-activity .comments-area .comments-table .comments-rows {
    display: flex;
    gap: 10px;
  }

  .question-bank-table-top .comments-bar .user-comment-activity .comments-area .comments-table .data-rows {
    display: flex;
    margin-left: 15%;
  }

  .question-bank-table-top .comments-bar .user-comment-activity .comments-area .comments-table .data-rows .user-name {
    color: #8e9eab;
  }

  .question-bank-table-top .comments-bar .user-comment-activity .comments-area .comments-table .action-head {
    font-size: 13px;
    font-weight: 400;
  }

  .question-bank-table-top .comments-bar .user-comment-activity .comments-area .comments-table .action-time {
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 10px;
    color: lightgray;
    border-bottom: 1px solid #e2ecf0;
    padding: 5px;
  }







  /**** Dropdown **/
  .user-status {
    width: 120px;

    text-align: center;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    font-size: 10px;
    height: 4vh;
  }

  .dropdown .dropdown-input {
    font-size: small;
    cursor: pointer;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
    background: #fff9e8;
    color: #ffcf00;
    border: 0.3px solid #ffcf00;
    font-size: x-small;
  }

  .dropdown .dropdown-input span {
    margin-left: 15px;
  }

  .dropdown-options {
    font-size: 10px;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .dropdown .dropdown-options .dropdown-option {
    cursor: pointer;
    padding: 8px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
  }

  .dropdown .dropdown-option:last-child {
    border-bottom: none;
  }

  .dropdown.open .dropdown-options {
    display: block;
  }

  .todo-comment-button {
    padding: 20px;
    padding-left: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .todo-comment-button .comm-bt {
    border-radius: 50px;
    width: 48px;
    height: 48px;
    border: 1px solid #ffffff;
    box-shadow: 0px 8px 40px 0px #7090b033;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .todo-comment-button .comm-bt:focus {
    border: 1px solid #00ABFB;
  }




















  /***************************************** COMMENT BOX USING STATES **************************/

  .question-bank-table-top .add-user-comment {
    height: 50vh;
  }

  .comments-bar.dynamic-comment-area {
    height: 50vh;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .dynamic-comment-area .comment-box {
    background-color: #fff;
    height: 20vh;
    width: 200px;
    margin-top: 5px;
    margin-left: 4px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    line-height: 30px;
    border: 1px solid lightgray;
    font-family: "IBM Plex Sans";

    color: #8e9eab;
  }

  .dynamic-comment-area .comment-box .enter {
    width: 190px;
    height: 10vh;
    max-width: fit-content;
    overflow-wrap: break-word;
    word-break: break-all;
  }

  .dynamic-comment-area .comment-box .enter:focus {
    outline: none;
  }

  .dynamic-comment-area .comment-box .type-comment-box {
    display: flex;
    align-items: center;
    width: 200px;
    margin-top: 4em;
    border-radius: 16px;
    gap: 3px;
    border: 1px solid #8e9eab;
  }

  .dynamic-comment-area .comment-box .type-comment-box input {
    width: 75px;
    border: none;
    height: 3vh;
  }

  .dynamic-comment-area .comment-box .type-comment-box input:focus {
    outline: none;
  }

  .dynamic-comment-area .comment-box .type-comment-box .send-bt {
    width: 30px;
    background: #ffffff;
    box-shadow: 0px 8px 40px rgba(112, 144, 176, 0.2);
    border-radius: 100px;
    height: 4vh;
    border: none;
    margin-left: 5em;
    cursor: pointer;
  }

  .dynamic-comment-area .view-other-comments {
    height: 25vh;
    padding: 5px;
  }

  .dynamic-comment-area .view-other-comments::-webkit-scrollbar {
    width: 0px;
  }

  .dynamic-comment-area .view-other-comments .task-id {
    width: 100px;
    background: #fff9e8;
    border-radius: 8px;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dynamic-comment-area .view-other-comments .dynamic-comment-table {
    font-size: 13px;
    font-weight: 400;
    font-family: "IBM Plex Sans";
  }

  .dynamic-comment-area .view-other-comments .dynamic-comment-table .user-name {
    color: #8e9eab;
  }

  .dynamic-comment-area .view-other-comments .dynamic-comment-table .dynamic-comment-row {
    font-size: 12px;
    gap: 10px;
    display: flex;
  }

  .dynamic-comment-area .view-other-comments .data-rows {
    display: flex;
    margin-left: 15%;
  }

  .dynamic-comment-area .view-other-comments .action-time {
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 10px;
    color: lightgray;
    border-bottom: 1px solid #e2ecf0;
    padding: 5px;
  }

  /**** CHANGING STATE OF COMMENTS AREA ON CLICKINNG OF BUTTON ***/
  .comments-screen .dynamic-comment-area {
    display: none;
    transition: opacity 0.5s ease;
  }

  .comments-screen .dynamic-comment-area.visible {
    display: block;
    opacity: 1;
  }









  /* review  */

  .review .question-bank-table-top {
    display: flex;
    height: 89vh;
  }

  .review .question-bank-table-top .question-bank {
    flex: 5;
    margin-right: 30px;
    font-family: "IBM Plex Sans", sans-serif;
  }


  .review .question-bank-table-top .question-bank {
    flex: 5;
    margin-right: 30px;
    border-radius: 12px;
  }


  .review .question-bank-table-top .question-bank .table-content-report .top-content-table .filter {
    display: flex;
    margin-left: 40em;
  }

  .review .question-bank-table-top .data-tags {
    font-family: "IBM Plex Sans", sans-serif;
  }

  .review .question-bank-table-top .data-tags .user-data-id {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 2em;
  }

  .review .question-bank-table-top .data-tags .user-data-id .some-text-tags {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
  }

  .review .question-bank-table-top .data-tags .some-text-tags .user-topics {
    display: flex;
    align-items: center;
    background-color: #e9f8ff;
    height: 3vh;
    border-radius: 50px;
    font-size: 10px;
    padding: 2px;
  }

  .review .question-bank-table-top .data-tags .user-data-id .user-subjects {
    margin-left: 12px;
  }

  .review .question-bank-table-top .data-tags .user-data-id .sub-lines {
    margin-left: 5px;
  }

  .review .question-bank-table-top .data-tags .user-genral-tags {
    font-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .review .question-bank-table-top .data-tags .user-genral-tags .tag-num {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: #f4f5f6;
    padding: 5px;
    height: 20px;
    width: 90px;
  }

  .review .question-bank-table-top .data-tag .some-tags {
    padding: 10px;
    padding-left: 1em;
  }
.Master_div {
  display: flex;
  justify-content: center;
}

.master_category {
  width: 70%;
}

.input_search_master {
  height: 100%;
  display: flex;

  width: 100%;
  position: relative;
}

.input_search_master input {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
  border-radius: 10px;
}

.search_icon {
  padding-left: 20px;
}

.category_table thead .first {
  text-align: left;
  padding-left: 5%;
  width: 25%;
}

.category_table thead .second {
  text-align: left;
  padding-left: 5%;
  width: 30%;
}

.category_table table tbody tr td {
  padding-left: 5%;
  font-size: 12px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.remove_master_one {
  display: flex;
  align-items: center;
  background-color: #f6f7fb;
  border-radius: 10px;
  padding: 8px 12px;
  justify-content: center;
}

.question_bank {
  width: 100%;
  /* padding: 30px 30px; */
}

.table_content_report {
  width: 100%;
  overflow-y: scroll;
  background-color: #ffffff;
  border-radius: 20px;
  margin-top: 2%;
  height: 380px;
}
/* style.table_content_report */

.table_content_report .top_content_table {
  width: 100%;
  height: 77px;
  display: flex;
  align-items: center;
}

.table_content_report .top_content_table .search_listing {
  width: 50%;
  height: 40px;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #eceff1;
}

.search_listing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid #d9d1d1; */
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  /* max-width: 574px; */
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search_listing .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  width: 100%;
}

.search_listing .tag_icon span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
}

.search_listing .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  display: flex;
  width: 100%;
  position: relative;
}

.search_listing .input_search input,
.search_listing .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
}

.input_search span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
  align-items: center;
  display: inline-flex;
}

.search_listing .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  width: 100%;
}

.search_listing .tag_icon span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
}

.filter {
  width: 20%;
  height: 24px;
  display: flex;
  justify-content: end;
  margin-right: 20px;
}

.filter div {
  display: inline-flex;
  align-items: center;
}

.filter div span {
  font-size: 14px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
}

.user_management_filter {
  justify-content: start;
}

.user_management_search_listing {
  margin: 10px 30px;
}

.add_question {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 108px;
}

.add_new_question {
  background-color: #00abfb;
  max-width: 228px;
  width: 100%;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_new_question span {
  font-size: 16px;
  color: #ffffff;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 20px;
  margin-left: 10px;
}

.pointer {
  cursor: pointer;
}

.sub_head_content_table table tbody tr td {
  border-bottom: 2px solid #f6f7fb !important;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 55px;
  padding-right: 13px;
}

.table_content_report .sub_head_content_table table {
  border-spacing: 0px !important;
  /* max-width: 1158px; */
  width: 100%;
}

.table_content_report .sub_head_content_table table thead {
  background-color: #e9f8ff;
  height: 41px;
  text-align: center;
  font-size: 12px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 14px;
}

.table_content_report .sub_head_content_table table thead .very_first {
  text-align: left !important;
  padding-left: 26px;
  height: 28px;
  /* width: 147px; */
}

.table_content_report .sub_head_content_table table thead .first {
  height: 28px;
  /* width: 110px; */
}

.table_content_report .sub_head_content_table table thead .second {
  height: 28px;
  /* width: 86px; */
}

.table_content_report .sub_head_content_table table thead .third {
  height: 28px;
  /* width: 105px; */
}

.table_content_report .sub_head_content_table table thead .fourth {
  height: 28px;
  /* width: 66px; */
}

.table_content_report .sub_head_content_table table thead .fifth {
  height: 28px;
  /* width: 74px; */
}

.table_content_report .sub_head_content_table table thead th span {
  margin-right: 4px;
}

.Identifier_table thead .first {
  text-align: left;
  padding-left: 5%;
  width: 25%;
}

.Identifier_table thead .second {
  text-align: left;
  padding-left: 5%;
  width: 25%;
}

.Identifier_table thead .third {
  text-align: left;
  padding-left: 5%;
  width: 25%;
}

.Identifier_table table tbody tr td {
  text-align: left;
  padding-left: 5%;

  font-size: 12px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.LOD thead .first {
  text-align: left;
  padding-left: 5%;
  width: 80%;
}

.LOD thead .fifth {
  text-align: left;
  padding-left: 5%;
  width: 20%;
}

.LOD table tbody tr td {
  text-align: left;
  padding-left: 5%;

  font-size: 12px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.master_subjectPipeline {
  width: 95%;
}

.test_type {
  border-radius: 50px;
  background-color: #f4f5f6;
  border: none;
  font-size: 10px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  padding: 5px 10px;
}

.action {
  /* margin-left: 26px; */
  margin-right: 26px;
  position: relative;
}

.action_buttons {
  box-shadow: 0px 0px 10px #ddd4d4;
  position: absolute;
  left: 10px;
  top: -15px;
  width: 90px;
  padding: 7px 15px;
  border-radius: 10px;
  z-index: 60;
}

.action_button .edit {
  padding: 5px 0px;
}

.action_button .duplicate {
  padding: 5px 0px;
}

.SubjectPipeline_table thead .first {
  text-align: left;
  padding-left: 5%;
  width: 10%;
}

.SubjectPipeline_table thead .second {
  text-align: left;
  padding-left: 5%;
  width: 10%;
}

.SubjectPipeline_table thead .fourth {
  text-align: center;
  padding-left: 5%;
  width: 40%;
}

.SubjectPipeline_table table tbody tr td {
  text-align: left;
  padding-left: 5%;

  font-size: 12px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.master_subjectPipeline {
  width: 95%;
}

.slugs_Tags {
  width: 90px;
  height: 28px;
  border-radius: 50px;
  background-color: #e9f8ff;
  border: none;
  /* font-size: 10px; */
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  padding: 5px 20px;
}

.question_bank_table_top {
  padding: 30px 30px;
}

.question_bank {
  width: 100%;
  /* padding: 30px 30px; */
}

.table_content_report {
  width: 100%;
  overflow-y: scroll;
  /* background-color: #ffffff; */
  border-radius: 20px;
  margin-top: 2%;
  height: 450px;
}

.reporting_content {
  height: 34.5em;
  background-color: #ffffff;
}
/* style.table_content_report */

.table_content_report .top_content_table {
  width: 100%;
  padding: 20px 0px 20px 0px;
  display: flex;
  align-items: center;
}

.table_content_report .top_content_table .table-title {
  height: 31px;
  gap: 24px;
  display: flex;
  width: 30%;
  margin-left: 20px;
}

.table_content_report .top_content_table .table-title span {
  font-size: 16px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 20px;
}

.table_content_report .top_content_table .search_listing {
  width: 50%;
  height: 40px;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #eceff1;
}

.search_listing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid #d9d1d1; */
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  /* max-width: 574px; */
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search_listing .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  width: 100%;
}

.search_listing .tag_icon span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
}

.search_listing .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  display: flex;
  width: 100%;
  position: relative;
}

.search_listing .input_search input,
.search_listing .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
}

.input_search span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
  align-items: center;
  display: inline-flex;
}

.search_listing .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  width: 100%;
}

.search_listing .tag_icon span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
}

.filter {
  width: 20%;
  height: 24px;
  display: flex;
  justify-content: end;
  margin-right: 20px;
}

.filter div {
  display: inline-flex;
  align-items: center;
}

.filter div span {
  font-size: 14px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
}

.user_management_filter {
  justify-content: start;
}

.add_question {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 108px;
}

.add_new_question {
  background-color: #00abfb;
  max-width: 228px;
  width: 100%;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_new_question span {
  font-size: 16px;
  color: #ffffff;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 20px;
  margin-left: 10px;
}

.pointer {
  cursor: pointer;
}

.sub_head_content_table table tbody tr td {
  border-bottom: 2px solid #f6f7fb !important;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 13px;
  padding-right: 13px;
}

.table_content_report .sub_head_content_table table {
  border-spacing: 0px !important;
  /* max-width: 1158px; */
  width: 100%;
}

.table_content_report .sub_head_content_table table thead {
  background-color: #e9f8ff;
  height: 41px;
  text-align: center;
  font-size: 12px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 14px;
}

.table_content_report .sub_head_content_table table thead .very_first {
  text-align: left !important;
  padding-left: 26px;
  height: 28px;
  /* width: 147px; */
}

.table_content_report .sub_head_content_table table thead .first {
  height: 28px;
  /* width: 110px; */
}

.table_content_report .sub_head_content_table table thead .second {
  height: 28px;
  /* width: 86px; */
}

.table_content_report .sub_head_content_table table thead .third {
  height: 28px;
  /* width: 105px; */
}

.table_content_report .sub_head_content_table table thead .fourth {
  height: 28px;
  /* width: 66px; */
}

.table_content_report .sub_head_content_table table thead .fifth {
  height: 28px;
  /* width: 74px; */
}

.table_content_report .sub_head_content_table table thead th span {
  margin-right: 4px;
}

.table_content_report .sub_head_content_table table tbody th {
  font-size: 12px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400 !important;
  text-align: center;
  border-bottom: 1px solid #f8eeee;
}

.user_management_table_body_content {
  font-size: 12px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.user_management_table_body_content .in_review {
  position: relative;
  display: inline-block;
  font-size: 10px;
  height: 4vh;

  font-size: small;
  cursor: pointer;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  background: #fff9e8;
  color: #ffcf00;
  border: 0.3px solid #ffcf00;
  font-size: x-small;
}

.todo_comment_button {
  padding: 20px;
  padding-left: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.todo_comment_button .comm_bt {
  border-radius: 50px;
  width: 48px;
  height: 48px;
  border: 1px solid #ffffff;
  box-shadow: 0px 8px 40px 0px #7090b033;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.todo_comment_button .comm_bt:focus {
  border: 1px solid #00abfb;
}

.action {
  /* margin-left: 26px; */
  margin-right: 26px;
  position: relative;
}

.top_section {
  display: block;
}

.top_section .left {
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
}

.top_section .right {
  display: inline-flex;
  float: right;
  align-items: center;
  color: #00abfb;
  font-size: 16px;
  font-weight: 400;
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 20px;
}

.profile_detail_div .status {
  float: right;
  background-color: #e9fcf1;
  border: 1px solid #26ab5b;
  color: #26ab5b;
  font-size: 16px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 20px;
  border-radius: 10px;
  padding: 5px 20px;
}

.profile_detail_div {
  font-size: 18px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 26px;
  padding: 20px 40px 10px 30px;
}

.profile_title_div {
  font-size: 22px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 26px;
  margin-left: 30px;
}

.title_detail {
  font-size: 14px;
  font-weight: 600;
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 20px;
}

.description_detail {
  font-size: 12px;
  font-weight: 400;
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 20px;
}

.profile_detail_info .second,
.profile_detail_info .third,
.profile_detail_info .fouth,
.profile_detail_info .last {
  text-align: left;
}

.user_profile .tags .subject p {
  font-size: 14px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.user_profile .tags .subject {
  width: 30%;
}

.user_profile .tags .subject input {
  width: 100%;

  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  width: 100%;
  /* height: 50px; */
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  padding: 20px;
  font-size: 12px;
}

.subject input::-webkit-outer-spin-button,
.subject input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.user_profile .tags {
  display: flex;
  gap: 30px;
  align-items: center;
}

.user_profile {
  padding: 0px 30px;
}

.profile_status {
  padding-left: 30px;
}
.question_list_of_task {
  padding: 0px !important;
}

.profile_status .info {
  padding: 20px 0px;
}

.profile_status .title {
  font-size: 14px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.select_question .info {
  display: flex;
  flex-wrap: wrap;
  gap: 56px;
  padding-bottom: 3.2%;
}

.select_question .info .format {
  max-height: 56px;
  max-width: 305px;
  width: 100%;
  height: 100%;
}

.select_question .info .format_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
}

.select_question .info .format_choice {
  display: flex;
  margin-top: 16px;
  gap: 26px;
}

.select_question .info .format_choice div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select_question .info .format_choice label {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  font-family: "IBM Plex Sans", sans-serif;
}

.select_question .info .format_choice input {
  margin: 0px;
  margin-right: 6px;
}

.red_mendantory {
  color: red;
}

.dropdown_css {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  padding: 0px 20px;
  font-size: 12px;
}

.roleBox {
  color: black;
  margin: 5px 10px;
  padding: 8px 12px 8px 12px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  line-height: 20px;
  background: #f6f7fb;
  border: none;
  border-radius: 10px;
}

.roleBox svg {
  margin-top: 5px;
  margin-left: 10px;
}

.Add_answer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.Add_answer span {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 8px;
  color: #00abfb;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.saveButton_questionBank {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
}

.saveButton_questionBank .cancel {
  text-decoration: underline;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: "IBM Plex Sans", sans-serif;
}

.margin_top_20 {
  margin-top: 20px;
}

.blue_button {
  background-color: #00abfb;
  color: white;
  width: 197px;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-family: "IBM Plex Sans", sans-serif;
  cursor: pointer;
}

.shadow {
  box-shadow: 0px 0px 5px rgb(198, 194, 194);
  border-radius: 10px;
}

.activate {
  background: #e9f8ff;
  color: #00abfb;
  width: 62px;
  height: 20px;
  border: 1px solid #00abfb;
  border-radius: 4px;
  font-size: 10px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  padding: 4px 8px 4px 8px;
  float: right;
}

.inactive {
  background-color: #f6f7fb;
  color: #8e9eab;
  width: 62px;
  height: 20px;
  border: 0.3px solid #8e9eab;
  border-radius: 4px;
  font-size: 10px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  padding: 4px 8px 4px 8px;
  float: right;
}

.tbody_question_bank {
  display: flex;
  width: 100%;
  font-size: 18px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 20px;
  border-bottom: 1px solid #ecf3f5;
}

.tbody_question_bank div {
  width: 100%;
  padding: 20px;
  text-align: center;
}

.tbody_question_bank .first {
  text-align: left;
}

.tbody_question_bank .last {
  border-left: 1px solid #ecf3f5;
}

.tbody_question_bank .second {
  border-left: 1px solid #ecf3f5;
}

.tbody_question_bank .third {
  border-left: 1px solid #ecf3f5;
}

.tbody_question_bank .fouth {
  border-left: 1px solid #ecf3f5;
}

.general_button {
  width: fit-content;
  height: 28px;
  border-radius: 50px;
  background-color: #f4f5f6;
  border: none;
  font-size: 10px;
  line-height: 20px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  flex-basis: calc(50% - 5px);
  margin-bottom: 5px;
  margin-top: 5px;
  color: #212529;
}

.question_id_tag {
  /* width: 420px; */
  height: 54px;
  display: inline-flex;
  margin-left: 13px;
  align-items: center;
}

.question_id_tag .id {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-decoration: underline;
  line-height: 16px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  margin-right: 25px;
}

.question_id_tag .tag {
  /* width: 349px; */
  text-align: center;
}

.question_id_tag .tag p {
  font-size: 12px;
  /* line-height: 14px; */
  margin-bottom: 5px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  text-align: center;
  height: 14px;
  margin-top: 0px;
}

.question_id_tag .tag span {
  font-size: 10px;
  line-height: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  height: 14px;
  background-color: #e9f8ff;
  border-radius: 50px;
}
.id_tags_column {
  text-align: left !important;
}

.input_search span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
  align-items: center;
  display: inline-flex;
}

.input_search .selected_tags {
  border-radius: 50px;
  background-color: #f4f5f6;
  padding: 2px 22px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;

  margin-bottom: 10px;
}

.cancel_button {
  float: right;
}

.cancel_button button {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list_input_primary {
  top: 55px;
  border: 1px solid white;
  background-color: white;
  box-shadow: 0px 5px 10px rgb(231, 226, 226);
  width: 90%;
  height: 20vh;
  padding: 10px;
  border-radius: 10px;
  z-index: 50;
  overflow-y: scroll;
}

.list_input_primary div {
  padding: 8px 5px;
}

.list_input_primary div:hover span {
  background-color: #00abfb;
  color: white;
}

.list_input_primary div span {
  border-radius: 50px;
  background-color: #f4f5f6;
  padding: 5px 22px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.input_search_ok span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
  align-items: center;
  display: inline-flex;
}

.input_search_ok .asignees {
  border-radius: 50px;
  background-color: #f4f5f6;
  padding: 2px 22px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;

  margin-bottom: 10px;
}

.try {
  display: flex;
  gap: 25px;
  padding: 5px;
}

.header_drop {
  height: 55px;
}

.header_drop select {
  display: flex;
  align-items: center;
  width: 315px;
  height: 53px;
  border: 1.5px solid #c6ccd2;
  border-radius: 10px;
  padding-left: 10px;
  font-size: 14px;
  color: #272727;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  line-height: 18px;
}

.user_management_search_listing {
  margin: 10px 30px;
}

.threePin {
  position: absolute;
  top: 50px;
  right: -70px;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: 1px solid black;
  padding: 16px;
  z-index: 1000;
  width: 120px;
  height: 60px;
  border: none;
  border-radius: 18px;
  box-shadow: 0px 8px 24px 0px #7090b026;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editPops {
  display: flex;
  gap: 10px;
}

.editPops .action {
  cursor: pointer;
}

.confirmation_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.confirmation_modal_content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 55%;
  height: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 30px;
  border: none;
}

.confirmation_modal_content p {
  font-size: 24px;
  font-weight: 600;
  color: #272727;
}

.confirmDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
}

.confirmDiv h5 {
  text-decoration: underline;
  font-size: 18px;
}

.confirmDiv button {
  width: 197px;
  height: 56px;
  background: #00abfb;
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 18px;
}
.break {
  box-shadow: 0px 8px 24px 0px #7090b026;
  height: 385px;
  border-radius: 16px;
  height: max-content;
  background-color: #ffffff;
}

.break_two {
  box-shadow: 8px 8px 24px 0px #7090b026;
  border-radius: 16px;
  background-color: #ffffff;
}

.advanced_filter {
  padding-left: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.advanced_filter .actual_filter {
  margin-top: -10px;
  display: flex;
  gap: 30px;
  align-items: center;
}

.actual_filter .dropTasks {
  width: 178px;
  background: url("../images/down.svg") no-repeat right center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: 40px 25px;
}
.dropTasks select {
  color: #8e9eab;
}

.reverse_drop {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
  cursor: pointer;
}

.subject textarea {
  font-family: "IBM Plex Sans", sans-serif;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(../images/date.svg) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: 20px 25px;
  cursor: pointer;
}

.flow::-webkit-scrollbar {
  display: none;
}

.flow {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.reverse_drop svg {
  transition: transform 0.3s ease-in-out;
}

.reverse_drop:active svg {
  transform: rotate(360deg);
}
.stored {
  border: 1px solid #00abfb;
    padding: 10px;
    margin: 10px;
    background-color:  #e9f8ff;
    text-align: center;
    flex: 1 1 auto;
    color: black;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    border-radius: 18px;
}

